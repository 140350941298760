import {
  PaginatedRequestParams,
  PaginatedResponse,
  RtkEmptySplitApi,
  ParamsWithManualUpdate,
  RtkBaseQuery,
} from "../../types";
import { fetchAllPages } from "../../utils";
import { EntitiesApiTag } from "../entities";
import {
  UserEntityStatus,
  UserLoginActivity,
  UserRole,
  User,
  UserDto,
  UserFile,
  transformUserDtoToUser,
  transformUserCPanelDtoToUserCPanelModel,
  UserCPanelModel,
  UserCPanelDto,
} from "./models";
import { GlobalUserPreferences } from "./models/common-types/GlobalUserPreferences";
import { UsersApiTag } from "./tags";
import { UserProfile, UserProfileGender, UserGeneralProfile } from "./types";
import { adaptUserGeneralProfileFromUser } from "./utils";

export type UsersApiGetUsersQueryParams = PaginatedRequestParams<{
  role?: string;
  spaces?: string;
  status?: UserEntityStatus | "all";
  time?: string;
  sort_by?: string;
  board_id?: string;
  user_id?: string;
  order?: "desc";
  exclude_workspace_id?: string;
  exclude?: string;
  search?: string;
  only_valid_profile?: boolean;
  only_reportees?: boolean;
}>;

export const addUsersApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [
      UsersApiTag.users,
      UsersApiTag.user,
      UsersApiTag.userProfile,
      UsersApiTag.generalUserProfile,
      UsersApiTag.userDocuments,
      EntitiesApiTag.getSubscriptionInfo,
    ],
  });

  const usersApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      getUserGeneralProfile: builder.query<
        UserGeneralProfile | null,
        ParamsWithManualUpdate<{
          entityId: string;
          params: { user_id: string };
        }>
      >({
        query: ({ params, entityId }) => ({
          url: `/api/auth/entity/${entityId}/users`,
          params,
        }),
        transformResponse: (response: PaginatedResponse<UserDto>) => {
          // TODO: check with backend
          if (!response.data?.[0]) {
            return null;
          }
          const user = transformUserDtoToUser(response.data?.[0]);
          if (!user) {
            return null;
          }
          return adaptUserGeneralProfileFromUser(user);
        },
        providesTags: (response) =>
          response
            ? [{ type: UsersApiTag.generalUserProfile, id: response.id }]
            : [],
      }),
      getUserProfile: builder.query<
        UserProfile,
        {
          params?: { user_id: string };
        } | void
      >({
        query: (data) => ({
          url: "/api/auth/profile-v2",
          params: data?.params,
          silentError: true,
        }),
        providesTags: (response) => [
          { type: UsersApiTag.userProfile, id: response?.user_id },
        ],
      }),
      getUsers: builder.query<
        PaginatedResponse<User>,
        {
          entityId: string;
          params?: UsersApiGetUsersQueryParams;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/users`,
          params,
        }),
        providesTags: [UsersApiTag.users],
        transformResponse: (response: PaginatedResponse<UserDto>) => ({
          ...response,
          data: response.data.map(transformUserDtoToUser),
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          queryFulfilled
            .then(({ data }) => {
              data?.data?.forEach((user) => {
                dispatch(
                  usersApi.util.upsertQueryData(
                    "getUserGeneralProfile",
                    {
                      params: { user_id: user.id },
                      entityId: args.entityId,
                    },
                    adaptUserGeneralProfileFromUser(user)
                  )
                );
              });
            })
            // Skip
            .catch(() => {});
        },
      }),
      getCPanelUsers: builder.query<
        PaginatedResponse<UserCPanelModel>,
        {
          entityId: string;
          params?: UsersApiGetUsersQueryParams;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/entity/${entityId}/users`,
          params: { ...params, is_cpanel: true },
        }),
        transformResponse: (response: PaginatedResponse<UserCPanelDto>) => ({
          ...response,
          data: response.data.map(transformUserCPanelDtoToUserCPanelModel),
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          queryFulfilled
            .then(({ data }) => {
              data?.data?.forEach((user) => {
                dispatch(
                  usersApi.util.upsertQueryData(
                    "getUserGeneralProfile",
                    {
                      params: { user_id: user.id },
                      entityId: args.entityId,
                    },
                    adaptUserGeneralProfileFromUser(user)
                  )
                );
              });
            })
            // Skip
            .catch(() => {});
        },
      }),

      // TODO: revisit and use v2
      createProfile: builder.mutation<
        UserProfile,
        { first_name: string; last_name: string; gender?: string; dob?: string }
      >({
        query: (params) => ({
          url: "/api/auth/entity/profile",
          method: "POST",
          // TODO: re-check
          data: {
            type: "general",
            country: "US",
            desc: {},
            visibility: {
              section: "1",
              first_name: "1",
              last_name: "1",
              mobile: "1",
              desc: "1",
            },
            nickname: `${params.first_name}${params.last_name}`,
            ...params,
          },
        }),
        invalidatesTags: (e) => [
          { type: UsersApiTag.userProfile, id: e?.user_id },
          { type: UsersApiTag.generalUserProfile, id: e?.user_id },
        ],
      }),

      updateUserProfilePicture: builder.mutation<
        {
          img: string;
          result: string;
          updated_at: string;
        },
        File | undefined
      >({
        query: (params) => {
          const data = new FormData();
          if (params) {
            data.set("display_picture_file", params);
          }
          return {
            url: "/api/auth/entity/profile/profile-picture",
            method: "POST",
            data: params ? data : { display_picture_file: {} },
          };
        },
        invalidatesTags: () => [
          UsersApiTag.userProfile,
          UsersApiTag.generalUserProfile,
        ],
      }),

      // TODO: revisit, move to v2
      updateUserProfile: builder.mutation<
        {
          item_type: "general";
          profile_item: {
            desc: unknown;
            dob: string;
            first_name: string;
            gender: string;
            id: string;
            img: string | null;
            lastName: string;
            mobile: string | null;
            nickname: string;
          };
          pubsub: {
            description: unknown[];
            img: string;
            info: string;
            mobile: string;
            name: string;
            nickname: string;
            profile_id: string;
            profile_item_id: string;
            updated_at: string;
            user_id: string;
          };
        },
        {
          user_id: string;
          data: {
            type: "general";
            first_name?: string;
            last_name?: string;
            gender?: UserProfileGender;
            birth?: string;
          };
        }
      >({
        query: ({ data, user_id }) => ({
          url: `/api/auth/entity/profile/${user_id}`,
          method: "PATCH",
          data,
        }),
        invalidatesTags: (e) => [
          { type: UsersApiTag.userProfile, id: e?.pubsub.user_id },
          { type: UsersApiTag.generalUserProfile, id: e?.pubsub.user_id },
        ],
      }),

      // TODO: types
      addUserProfileSection: builder.mutation({
        query: ({ data }) => ({
          url: `/api/auth/profile-v2`,
          method: "POST",
          data,
        }),
        invalidatesTags: (e) => [
          { type: UsersApiTag.userProfile, id: e?.pubsub.user_id },
        ],
      }),
      // TODO: types
      updateUserProfileSection: builder.mutation({
        query: ({ data, item_id }) => ({
          url: `/api/auth/profile-v2/${item_id}`,
          method: "PUT",
          data,
        }),
        invalidatesTags: (e) => [
          { type: UsersApiTag.userProfile, id: e?.pubsub.user_id },
        ],
      }),
      // TODO: types
      deleteUserProfileSection: builder.mutation({
        query: ({ type, item_id }) => ({
          url: `/api/auth/profile-v2/${item_id}/?type=${type}`,
          method: "DELETE",
        }),
        invalidatesTags: (e) => [
          { type: UsersApiTag.userProfile, id: e?.user_id },
        ],
      }),

      searchForProfiles: builder.query<
        { data: UserProfile[] },
        {
          data: {
            input: string;
            entity_id?: string;
          };
        }
      >({
        query: ({ data }) => ({
          url: "/api/auth/profile/search",
          method: "POST",
          data,
        }),
      }),

      _DEPRECATED_getAllUsers: builder.query<User[], { entityId: string }>({
        queryFn: async ({ entityId }, _queryApi, _extraOptions, baseQuery) => {
          try {
            const allEntitiesDto = await fetchAllPages<UserDto>(
              `/api/auth/entity/${entityId}/users`,
              { status: "all" },
              baseQuery as unknown as RtkBaseQuery
            );
            return {
              data: allEntitiesDto.map(transformUserDtoToUser),
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: [UsersApiTag.users],
      }),
      sendEntityInvitation: builder.mutation<
        User,
        {
          entityId: string;
          data: { username: string; mobiles: string[]; emails: string[] };
        }
      >({
        query: ({ entityId, data }) => ({
          url: `/api/auth/entity/${entityId}/users`,
          method: "POST",
          data: { users: [{ ...data, role: UserRole.taskEmployee }] },
        }),
        transformResponse: (response: PaginatedResponse<UserDto>) =>
          transformUserDtoToUser(response.data?.[0]),
        invalidatesTags: (response, error, args) =>
          response
            ? [
                UsersApiTag.users,
                { type: EntitiesApiTag.getSubscriptionInfo, id: args.entityId },
              ]
            : [],
      }),
      resendEntityInvitation: builder.mutation<
        { result: "success" },
        {
          entityId: string;
          entityRelId: string;
          data: { username: string; mobiles: string[]; emails: string[] };
        }
      >({
        query: ({ entityId, data, entityRelId }) => ({
          url: `/api/auth/entity/${entityId}/users/${entityRelId}`,
          method: "PUT",
          data,
        }),
        invalidatesTags: [UsersApiTag.users],
      }),

      updateUser: builder.mutation<
        UserDto,
        {
          entityId: string;
          entityRelId: string;
          data: Partial<UserDto> & { dept_id?: string | null };
        }
      >({
        query: ({ data, entityId, entityRelId }) => ({
          url: `/api/auth/entity/${entityId}/users/${entityRelId}`,
          method: "PUT",
          data,
        }),
        invalidatesTags: (response) => [
          ...(response?.user?.id
            ? [{ type: UsersApiTag.generalUserProfile, id: response.user?.id }]
            : []),
          UsersApiTag.users,
        ],
      }),
      deleteUser: builder.mutation<
        void,
        { entityId: string; entityRelId: string }
      >({
        query: ({ entityRelId, entityId }) => ({
          url: `/api/auth/entity/${entityId}/users/${entityRelId}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, { entityId }) => [
          UsersApiTag.users,
          { type: EntitiesApiTag.getSubscriptionInfo, id: entityId },
        ],
      }),
      deleteMyAccount: builder.mutation<void, void>({
        query: () => ({
          url: "/api/auth/user",
          method: "DELETE",
        }),
      }),

      updateUsersDepartment: builder.mutation<
        PaginatedResponse<User>,
        {
          entityId: string;
          data: {
            dept_id: string;
            entity_rel_ids: string[];
          };
        }
      >({
        query: ({ entityId, data }) => ({
          url: `/api/auth/entity/${entityId}/users/update_dept`,
          method: "POST",
          data,
        }),
        invalidatesTags: (e0, e1, { data: { entity_rel_ids } }) => [
          UsersApiTag.users,
          ...entity_rel_ids.map((id) => ({
            type: UsersApiTag.generalUserProfile,
            id,
          })),
        ],
      }),
      getUserDocuments: builder.query<
        UserFile[],
        {
          entityId: string;
          userId: string;
        }
      >({
        query: ({ entityId, userId }) => ({
          url: `/api/auth/entity/${entityId}/users/${userId}/docs`,
        }),
        providesTags: (a0, a1, { userId }) => [
          { type: UsersApiTag.userDocuments, id: userId },
        ],
      }),
      getUserLoginHistory: builder.query<
        PaginatedResponse<UserLoginActivity>,
        {
          entityId: string;
          userId: string;
          params?: PaginatedRequestParams;
        }
      >({
        query: ({ entityId, userId, params }) => ({
          url: `/api/auth/entity/${entityId}/users/${userId}/login_history`,
          params,
        }),
      }),
      renameDocument: builder.mutation<
        {
          entity_id: string;
          name: string;
          id: string;
        },
        {
          entityId: string;
          name: string;
          id: string;
          userId: string;
        }
      >({
        query: ({ entityId, ...data }) => ({
          url: `/api/auth/drive/entity/${entityId}/rename`,
          method: "PUT",
          data,
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            usersApi.util.updateQueryData(
              "getUserDocuments",
              {
                entityId: args.entityId,
                userId: args.userId,
              },
              (documentsDraft) =>
                documentsDraft.map((document) =>
                  document.id === args.id
                    ? {
                        ...document,
                        name: args.name,
                      }
                    : document
                )
            )
          );
          queryFulfilled.catch(patchResult.undo);
        },
      }),
      deleteDocument: builder.mutation<
        void,
        {
          entityId: string;
          id: string;
          userId: string;
        }
      >({
        query: ({ entityId, id }) => ({
          url: `/api/auth/drive/entity/${entityId}/${id}`,
          method: "DELETE",
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const updateDocumentsResult = dispatch(
            usersApi.util.updateQueryData(
              "getUserDocuments",
              {
                entityId: args.entityId,
                userId: args.userId,
              },
              (documentsDraft) =>
                documentsDraft.filter((document) => document.id !== args.id)
            )
          );
          queryFulfilled.catch(() => {
            updateDocumentsResult.undo();
          });
        },
        invalidatesTags: [UsersApiTag.users],
      }),
      updateMyUser: builder.mutation<{ id: string }, Pick<User, "timezone">>({
        query: ({ timezone }) => ({
          url: `/api/auth/user`,
          method: "PUT",
          data: {
            timezone,
          },
        }),
        invalidatesTags: (result) =>
          result
            ? [{ type: UsersApiTag.generalUserProfile, id: result.id }]
            : [],
      }),
      getGlobalPreferences: builder.query<GlobalUserPreferences, void>({
        query: () => ({
          url: "/api/auth/user/preferences",
          silentError: true,
        }),
      }),
      updateGlobalPreferences: builder.mutation<
        void,
        {
          data: {
            onboarding_steps: GlobalUserPreferences;
          };
        }
      >({
        query: ({ data }) => ({
          url: "/api/auth/user/preferences",
          data: {
            preferences: data,
          },
          method: "POST",
        }),
        onQueryStarted: (args, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            usersApi.util.updateQueryData(
              "getGlobalPreferences",
              undefined,
              (onboardingStatusDraft) => ({
                ...onboardingStatusDraft,
                ...args.data.onboarding_steps,
              })
            )
          );

          queryFulfilled.catch(patchResult.undo);
        },
      }),
    }),
    overrideExisting: false,
  });

  return usersApi;
};

export type UsersApi = ReturnType<typeof addUsersApi>;
