import { ManageTaskFieldsDialog } from "@jugl-web/domain-resources/tasks/components/ManageTaskFieldsDialog";
import { ManageTaskFieldsDialogScreenToParametersMap } from "@jugl-web/domain-resources/tasks/components/ManageTaskFieldsDialog/types";
import { MultiSectionLayout } from "@jugl-web/ui-components/cross-platform/content/MultiSectionLayout";
import {
  HeaderBreadcrumbs,
  HeaderBreadcrumbsItem,
} from "@jugl-web/ui-components/web/HeaderBreadcrumbs";
import { cx } from "@jugl-web/utils";
import { Screen } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { ExportTasksDialog } from "@web-src/features/tasks/ExportTasksDialog";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { OnboardingProgressBar } from "@web-src/modules/preferences/components/OnboardingProgressBar";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { FC, ReactNode, useMemo, useState } from "react";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { ArchiveTopBarButton } from "../ArchiveTopBarButton";
import { ManageFieldsTopBarButton } from "../ManageFieldsTopBarButton";
import { TemplatesTopBarButton } from "../TemplatesTopBarButton";
import { TasksImportDialog } from "./components/TasksImportDialog";
import { TasksTopBarMenu } from "./components/TasksTopBarMenu";

interface TasksPageLayoutProps {
  children: ReactNode;
  breadcrumbs: HeaderBreadcrumbsItem[];
  topBarRightContent?: ReactNode;
  hideRightContent?: boolean;
  className?: string;
}

export const TasksPageLayout = ({
  children,
  breadcrumbs,
  topBarRightContent,
  hideRightContent,
  className,
}: TasksPageLayoutProps) => {
  const [isManageFieldsDialogOpen, setIsManageFieldsDialogOpen] =
    useState(false);

  const [isImportTasksDialogOpen, setIsImportTasksDialogOpen] = useState(false);
  const [isTaskExportDialogOpen, setIsTaskExportDialogOpen] = useState(false);

  const initialManageFieldsDialogScreen = useMemo<
    Screen<ManageTaskFieldsDialogScreenToParametersMap>
  >(() => ({ name: "entry" }), []);

  const { entity } = useEntitySelectedProvider();
  const { isOnboardingActive } = useOnboarding();

  const { hasEmployeeLikeRole } = useUserRole();

  return (
    <>
      <MultiSectionLayout
        header={
          <HeaderBreadcrumbs
            items={breadcrumbs}
            rightContent={
              <div
                className={cx("flex items-center gap-4", {
                  hidden: hideRightContent,
                })}
              >
                {topBarRightContent}
                {!hasEmployeeLikeRole && <TemplatesTopBarButton />}
                <ArchiveTopBarButton />
                {!hasEmployeeLikeRole && (
                  <>
                    <ManageFieldsTopBarButton
                      onClick={() => setIsManageFieldsDialogOpen(true)}
                    />
                    <TasksTopBarMenu
                      onTasksImport={() => setIsImportTasksDialogOpen(true)}
                      onTasksExport={() => setIsTaskExportDialogOpen(true)}
                    />
                  </>
                )}
              </div>
            }
          />
        }
      >
        <div className={cx("flex h-full flex-col bg-gray-100", className)}>
          {children}
          {isOnboardingActive && <OnboardingProgressBar />}
        </div>
      </MultiSectionLayout>
      <ManageTaskFieldsDialog
        isOpen={isManageFieldsDialogOpen}
        entityId={entity.id}
        initialScreen={initialManageFieldsDialogScreen}
        onClose={() => setIsManageFieldsDialogOpen(false)}
      />
      <TasksImportDialog
        isOpen={isImportTasksDialogOpen}
        onRequestClose={() => setIsImportTasksDialogOpen(false)}
      />
      <ExportTasksDialog
        isOpen={isTaskExportDialogOpen}
        onClose={() => setIsTaskExportDialogOpen(false)}
      />
    </>
  );
};

TasksPageLayout.ControlBar = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={cx(
      "flex h-[72px] shrink-0 items-center justify-between gap-4 bg-[#EEF2F5] px-8",
      "border-grey-300 border-b border-l-0 border-r-0 border-t-0 border-solid",
      className
    )}
  >
    {children}
  </div>
);

export const TASKS_PAGE_LAYOUT_CONTENT_CONTAINER_ID =
  "tasks-page-layout-content-container";

type ContentBackgroundColor = "white" | "grey";

interface ContentProps {
  children: ReactNode;
  backgroundColor?: ContentBackgroundColor;
  className?: string;
}

const backgroundColorToClasses: Record<ContentBackgroundColor, string> = {
  white: "bg-white",
  grey: "bg-[#EEF2F5]",
};

TasksPageLayout.Content = (({
  children,
  backgroundColor = "white",
  className,
}) => (
  <div
    className={cx(
      "relative h-full overflow-x-auto",
      backgroundColorToClasses[backgroundColor],
      className
    )}
  >
    <div
      id={TASKS_PAGE_LAYOUT_CONTENT_CONTAINER_ID}
      className="relative flex h-full flex-col overflow-x-auto"
    >
      {children}
    </div>
  </div>
)) as FC<ContentProps>;
