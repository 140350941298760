import { EntityModel, useRestApiProvider } from "@jugl-web/rest-api";
import {
  usePaginationUpdate,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import useLiveConversationReceipts from "@web-src/features/chats/hooks/useLiveConversationReceipts";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { singletonHook } from "react-singleton-hook";
import { useNavigateToRoleBasedInitialPage } from "./useNavigateToRoleBasedInitialPage";
import { setEntity } from "../appSlice";

const useSelectEntityHook = () => {
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();
  const { entity: selectedEntity } = useEntityProvider();
  const [entityToShowToast, setEntityToShowToast] = useState<EntityModel>();
  useEffect(() => {
    if (
      !entityToShowToast ||
      !selectedEntity ||
      entityToShowToast.id !== selectedEntity?.id
    ) {
      return;
    }
    toast(
      <span>
        {t(
          {
            id: "feedback.switched-to-entity",
            defaultMessage: "Switched to {entityName}",
          },
          { entityName: <b>{entityToShowToast.name}</b> }
        )}
      </span>,
      { autoHideDuration: 3000 }
    );
    setEntityToShowToast(undefined);
  }, [entityToShowToast, selectedEntity, t, toast]);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { entitiesApi } = useRestApiProvider();

  const { reset: resetLiveConversationReceipts } =
    useLiveConversationReceipts();

  const { resetAll: resetAllPagination } = usePaginationUpdate();

  const [markEntityAsRead] = entitiesApi.useMarkEntityAsReadMutation();

  const navigateToRoleBasedInitialPage = useNavigateToRoleBasedInitialPage();

  const selectEntity = useCallback(
    (entity: EntityModel, showToast?: boolean, customInitialPage?: string) => {
      // Resets all state related to the previous entity (if any)
      resetLiveConversationReceipts?.();
      resetAllPagination();

      // Marks entity as read if it has any unread updates
      if (entity.unread) {
        markEntityAsRead({ entityId: entity.id });
      }

      // Sets the new entity in the store
      dispatch(setEntity(entity));

      if (showToast) {
        setEntityToShowToast(entity);
      } else {
        setEntityToShowToast(undefined);
      }

      if (customInitialPage) {
        navigate(customInitialPage);
      } else {
        navigateToRoleBasedInitialPage(entity);
      }
    },
    [
      resetLiveConversationReceipts,
      resetAllPagination,
      dispatch,
      markEntityAsRead,
      navigate,
      navigateToRoleBasedInitialPage,
    ]
  );
  return { selectEntity };
};

export const useSelectEntity =
  singletonHook({ selectEntity: () => undefined }, useSelectEntityHook) || {};
