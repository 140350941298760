import React, { useCallback, useEffect, useState } from "react";
import { cx, useToast } from "@jugl-web/utils";
import {
  EntitySpaceModel,
  useRestApiProvider,
  UserRole,
} from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { PeopleUserItem } from "@web-src/modules/people/components/PeopleUserItem";
import {
  Alert,
  Button,
  PageLoaderFull,
} from "@jugl-web/ui-components/cross-platform";
import PeopleInfoSidebar from "@web-src/features/people/components/PeopleInfoSidebar";
import { ReactComponent as ChevronDown } from "./assets/chevron-down.svg";
import { ReactComponent as EditIcon } from "./assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete-icon.svg";
import { DepartmentFormSidebar } from "../DepartmentFormSidebar";
import { useTeamsPageContext } from "../../TeamsPageContext";

export const TeamCard: React.FC<{
  space: EntitySpaceModel;
}> = ({ space }) => {
  const { entityId, entity } = useEntitySelectedProvider();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isEditSidebarOpen, setIsEditSidebarOpen] = useState(false);
  const [isRemoveAlertOpen, setIsRemoveAlertOpen] = useState(false);
  const { refetchTrigger$ } = useTeamsPageContext();

  // TODO: temporary hack due to refetch not always working correctly on
  // headless users list
  const [usersForceHide, setUsersForceHide] = useState(false);
  const [profileIdToOpen, setProfileIdToOpen] = useState<string | null>(null);
  useEffect(() => {
    const subscription = refetchTrigger$.subscribe(() => {
      setUsersForceHide(true);
      setTimeout(() => {
        setUsersForceHide(false);
      }, 1000);
    });
    return () => subscription.unsubscribe();
  }, [refetchTrigger$]);

  const { toast } = useToast({ variant: "web" });

  const { entitySpacesApi } = useRestApiProvider();
  const [removeEntitySpace, { isLoading: isRemoveInProgress }] =
    entitySpacesApi.useRemoveEntitySpaceMutation();

  const handleRemoveSpace = useCallback(async () => {
    const response = await removeEntitySpace({ entityId, spaceId: space.id });
    if ("error" in response && response.error) {
      return;
    }
    toast(`${space.info.title} successfully deleted`, {
      autoHideDuration: 3000,
    });
    setIsRemoveAlertOpen(false);
  }, [entityId, removeEntitySpace, space.id, space.info.title, toast]);

  return (
    <>
      <div className="bg-grey-100 rounded-3xl">
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex h-[80px] cursor-pointer items-center justify-between px-6"
        >
          <div className="flex items-center gap-2">
            <div
              className={cx("transition-transform", {
                "rotate-180": isExpanded,
              })}
            >
              <ChevronDown />
            </div>
            <span className="select-none font-semibold">
              {space.info.title}
            </span>
          </div>
          {entity.role === UserRole.admin && (
            <div className="flex gap-2">
              <Button
                size="small"
                iconStart={<EditIcon />}
                variant="text"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditSidebarOpen(true);
                }}
              >
                {isExpanded && "Edit"}
              </Button>
              <Button
                size="small"
                iconStart={<DeleteIcon />}
                variant="text"
                color="tertiary"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRemoveAlertOpen(true);
                }}
              >
                {isExpanded && "Delete"}
              </Button>
            </div>
          )}
        </div>
        {isExpanded && !usersForceHide && (
          <HeadlessUsersList
            fetchParams={{ entityId, departments: space.id }}
            userRenderer={(user) => (
              <PeopleUserItem
                entityId={entityId}
                userId={user.id}
                onClick={() => setProfileIdToOpen(user.id)}
                className="border-none"
              />
            )}
          />
        )}
      </div>
      {isEditSidebarOpen && (
        <DepartmentFormSidebar
          isOpen
          inEditMode
          onRequestClose={() => setIsEditSidebarOpen(false)}
          editedSpace={space}
        />
      )}
      {profileIdToOpen && (
        <PeopleInfoSidebar
          isOpen
          userId={profileIdToOpen || ""}
          onClose={() => setProfileIdToOpen(null)}
        />
      )}
      <Alert
        isOpen={isRemoveAlertOpen}
        header="Remove team"
        title={`${space.info.title} will be removed`}
        content={`Do you really want to delete the ${space.info.title} team`}
        onRequestClose={() => setIsRemoveAlertOpen(false)}
        buttons={[
          {
            text: "Cancel",
            onClick: () => setIsRemoveAlertOpen(false),
            color: "grey",
          },
          {
            text: "Delete",
            onClick: handleRemoveSpace,
            color: "tertiary",
          },
        ]}
      />
      <PageLoaderFull isTransparent isActive={isRemoveInProgress} />
    </>
  );
};
