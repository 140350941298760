import { appModulesToRequiredUserRoles } from "@jugl-web/domain-resources/common/consts";
import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { OrderFormsPage } from "./OrderFormsPage";
import { OrderFormSubmitPage } from "./OrderFormSubmitPage";
import { OrderFormWizardPage } from "./OrderFormWizardPage";

export type OrdersPagesNavigationParams = {
  orderForms: PageNavParams;
  orderFormCreate: PageNavParams;
  orderFormEdit: PageNavParams<
    {
      formId: string;
    },
    {
      flow: string;
    }
  >;
  orderFormSubmit: PageNavParams<{
    formId: string;
  }>;
};

export const ordersPagesNavigationConfig: ModulePagesConfig<OrdersPagesNavigationParams> =
  {
    orderForms: {
      element: <OrderFormsPage />,
      path: "orders/forms",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.orderForms,
    },
    orderFormCreate: {
      element: <OrderFormWizardPage />,
      path: "orders/forms/create",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.orderForms,
    },
    orderFormEdit: {
      element: <OrderFormWizardPage />,
      path: "orders/forms/edit/:formId",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.orderForms,
    },
    orderFormSubmit: {
      element: <OrderFormSubmitPage />,
      path: "orders/forms/submit/:formId",
      isPrivate: true,
      requiredUserRoles: appModulesToRequiredUserRoles.orderForms,
    },
  };
