import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import UserProfileAvatar from "@web-src/features/users/components/UserProfileAvatar";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import cx from "classnames";
import React, { ReactNode, useCallback, useState } from "react";
import { usePopper } from "react-popper";
import { useDispatch } from "react-redux";
import { useTranslations } from "@jugl-web/utils";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { MENTIONS_ALL_ID } from "@jugl-web/utils/consts";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { setOpenedUserProfile } from "../../chatsSlice";
import useNavigateToChat from "../../hooks/useNavigateToChat";
import { ReactComponent as MessageArrowLeftIcon } from "./images/mention-arrow-left.svg";
import { ReactComponent as MessageIcon } from "./images/mention-message.svg";

const ChatMessageMember: React.FC<{ text: ReactNode; userId?: string }> = ({
  text,
  userId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const [btnRef, setBtnRef] = useState<HTMLButtonElement | null>();
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>();
  const { styles: popperStyles, attributes: popperAttrs } = usePopper(
    btnRef,
    popoverRef
  );

  const { entity } = useEntitySelectedProvider();
  const { profile } = useUserGeneralProfile({
    userId,
    entityId: entity.id,
    skip: userId === MENTIONS_ALL_ID,
  });
  const department = profile?.department?.name;

  const onMouseEnter = useCallback(
    (open: boolean) => {
      if (open) {
        return;
      }
      btnRef?.click();
    },
    [btnRef]
  );

  const navigateToChat = useNavigateToChat();
  return (
    <Popover className="inline-block">
      {({ close, open }) => (
        <span onMouseLeave={close}>
          <PopoverButton
            as="span"
            ref={setBtnRef}
            className={cx(
              "bg-primary-50 text-primary-500 inline-block rounded border-none px-[4px] py-[2px] text-[16px] font-[500] text-[500] focus:outline-none",
              {
                "cursor-pointer": userId !== MENTIONS_ALL_ID,
              }
            )}
            style={{
              fontFamily: "Roboto",
            }}
            disabled={userId === MENTIONS_ALL_ID}
            onMouseEnter={onMouseEnter.bind(null, open)}
          >
            {text}
          </PopoverButton>
          <PopoverPanel
            className="z-50"
            ref={setPopoverRef}
            style={popperStyles.popper}
            {...popperAttrs.popper}
          >
            <div className="bg-white p-[16px] text-gray-500 shadow">
              <div className="flex items-center">
                <div className="mr-[24px] h-[100px] w-[100px]">
                  <UserProfileAvatar
                    userId={userId}
                    wrapperClassName="h-[100px] w-[100px] overflow-hidden rounded-[50px] flex items-center justify-center bg-gray-100"
                    className="max-h-[100px] max-w-[100px]"
                    lettersClassName="text-[30px]"
                  />
                </div>
                <div>
                  <Text
                    variant="h4"
                    className="m-0 mb-[4px] whitespace-nowrap text-black"
                  >
                    <UserProfileName userId={userId} />
                  </Text>
                  <div
                    className={cx(
                      "inline-block whitespace-nowrap rounded border border-solid px-[8px] py-[2px] text-[14px] font-[500]",
                      {
                        "border-primary-500 text-primary-500 bg-primary-50":
                          department,
                        "border-gray-500 bg-gray-50 text-gray-500": !department,
                      }
                    )}
                  >
                    {department ||
                      t({
                        id: "common.no-department",
                        defaultMessage: "No department",
                      })}
                  </div>
                </div>
              </div>
              <div className="my-[16px] h-[1px] bg-gray-200" />
              <div className="flex items-center gap-6">
                {profile &&
                  hasRoleAccessToModule(profile.role, AppModule.chat) && (
                    <div
                      className="bg-primary-500 hover:bg-primary-600 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[20px] shadow"
                      onClick={
                        userId
                          ? navigateToChat.bind(null, userId, undefined)
                          : undefined
                      }
                    >
                      <MessageIcon />
                    </div>
                  )}
                <div
                  className="bg-secondary-500 hover:bg-secondary-600 flex h-[40px] cursor-pointer items-center justify-center rounded px-[10px] text-white"
                  onClick={() => {
                    dispatch(setOpenedUserProfile(userId));
                    close();
                  }}
                >
                  {t({
                    id: "chats-page.open-profile",
                    defaultMessage: "Open profile",
                  })}{" "}
                  <MessageArrowLeftIcon />
                </div>
              </div>
            </div>
          </PopoverPanel>
        </span>
      )}
    </Popover>
  );
};

export default ChatMessageMember;
