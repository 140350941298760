import { AvatarStack } from "@jugl-web/ui-components/cross-platform/AvatarStack";
import { ListBoxProps } from "@jugl-web/ui-components/cross-platform/ListBox";
import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { ResourcePickerDrawer } from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useState } from "react";
import { useUserListBox } from "../../../../../users/hooks/useUserListBox/useUserListBox";
import { HeadlessUserItem } from "../../../../../users/hooks/useHeadlessUsersList";
import { useMultipleUserProfiles } from "../../../../../users/hooks/useMultipleUserProfiles";
import { ReactComponent as AssigneeIcon } from "../../assets/assignee.svg";
import { FieldComponentProps } from "../../types";
import { AppModule } from "../../../../../common/types";

interface AssigneesFieldProps extends FieldComponentProps<"assignees"> {
  entityId: string;
}

export const AssigneesField: FC<AssigneesFieldProps> = ({
  entityId,
  assigneeIds,
  onlyReportees,
  isReadonly,
  isHidden,
  isLabelHidden,
  onChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const { profiles: assigneeProfiles } = useMultipleUserProfiles({
    entityId,
    userIds: assigneeIds,
    skip: isHidden,
  });

  const hasAnyAssignee = assigneeIds.length > 0;
  const isFieldVisible = hasAnyAssignee || !isReadonly;

  const { getListBoxProps, getTitleProps, clearSearchQuery } = useUserListBox({
    entityId,
    skipLoading: !isFieldVisible || isHidden,
    onlyReportees,
    module: AppModule.tasks,
  });

  const commonButtonProps: TaskPropertyButtonProps = {
    isDisabled: isReadonly,
    className: cx(hasAnyAssignee && "bg-primary-50 text-primary-900"),
    startIcon: hasAnyAssignee ? undefined : <AssigneeIcon />,
    children: hasAnyAssignee ? (
      <AvatarStack
        size="xs"
        maxCount={3}
        members={assigneeProfiles.map((profile) => ({
          firstName: profile.firstName || "",
          lastName: profile.lastName || "",
          imageUrl: profile.avatar || "",
        }))}
      />
    ) : !isLabelHidden ? (
      t({
        id: "tasks-page.assignee",
        defaultMessage: "Assignee",
      })
    ) : null,
  };

  const commonResourcePickerProps = {
    ...getListBoxProps({
      selectionBehavior: { mode: "multiple" },
      defaultSelectedIds: assigneeIds,
    }),
    ...getTitleProps(),
  } satisfies ListBoxProps<HeadlessUserItem | null>;

  if (!isFieldVisible || isHidden) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          onSubmit={onChange}
          onClose={() => setIsDialogOpen(false)}
          onTransitionEnd={() => {
            if (!isDialogOpen) {
              clearSearchQuery();
            }
          }}
          {...commonResourcePickerProps}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      placement="bottom-start"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
        />
      )}
      onSubmit={(item) => {
        onChange?.(item);
        clearSearchQuery();
      }}
      onUnmount={clearSearchQuery}
      {...commonResourcePickerProps}
    />
  );
};
