import { Tooltip } from "@jugl-web/ui-components";
import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import {
  ListBoxItemComponent,
  ListBoxItemComponentProps,
} from "@jugl-web/ui-components/cross-platform/ListBox";
import { FC, useMemo } from "react";
import { useUserGeneralProfile } from "../../hooks/useUserGeneralProfile";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import { useUserRolePermissionWarning } from "../../hooks/useUserRolePermissionWarning";
import { AppModule } from "../../../common/types";
import { hasRoleAccessToModule } from "../../utils/hasRoleAccessToModule";

interface UserListBoxItemProps extends ListBoxItemComponentProps {
  entityId: string;
  userId: string;
  module?: AppModule;
}

export const UserListBoxItem: FC<UserListBoxItemProps> = ({
  entityId,
  userId,
  module,
  ...listBoxItemProps
}) => {
  const { profile, safeProfile } = useUserGeneralProfile({ entityId, userId });
  const getUserRolePermissionWarning = useUserRolePermissionWarning();

  const isDisabled = useMemo(() => {
    if (!profile || listBoxItemProps.isSelected || !module) {
      return false;
    }

    return !hasRoleAccessToModule(profile.role, module);
  }, [listBoxItemProps.isSelected, profile, module]);

  return (
    <ListBoxItemComponent
      {...listBoxItemProps}
      label={safeProfile.displayName}
      startSlot={
        <Avatar
          className="border border-solid border-white"
          size="md"
          imageUrl={safeProfile.avatar}
          username={safeProfile.displayName}
        />
      }
      isDisabled={isDisabled}
      endSlot={
        isDisabled ? (
          <Tooltip
            renderTrigger={({ props, ref }) => (
              <InfoIcon ref={ref} {...props} />
            )}
          >
            {getUserRolePermissionWarning(module)}
          </Tooltip>
        ) : undefined
      }
    />
  );
};
