import React, { useCallback, useState } from "react";
import SideBar from "@web-src/components/SideBar";
import Button from "@mui/material/Button";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useMe } from "@web-src/features/app/hooks/useMe";
import SearchInputUnderline from "@web-src/components/SearchInputUnderline/SearchInputUnderline";
import Stack from "@mui/material/Stack";
import { useTranslations } from "@jugl-web/utils";
import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { UserGeneralProfile } from "@jugl-web/rest-api";
import { ListHeading } from "@jugl-web/ui-components/cross-platform/list/ListHeading";
import { LoadingSpinner } from "@jugl-web/ui-components/cross-platform";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { useUserRolePermissionWarning } from "@jugl-web/domain-resources/users/hooks/useUserRolePermissionWarning";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import useNavigateToChat from "../../hooks/useNavigateToChat";

export const CreatePersonalChatSidebar: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { entity } = useEntity();
  // TODO: add isLoading
  // TODO: pagination?
  const { id: myId } = useMe();
  const navigateToChat = useNavigateToChat();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { t } = useTranslations();
  const getUserRolePermissionWarning = useUserRolePermissionWarning();

  const [selectedUser, setSelectedUser] = useState<string>();

  const handleSubmit = useCallback(() => {
    if (!selectedUser) {
      return;
    }
    navigateToChat(selectedUser);
    onRequestClose();
  }, [navigateToChat, onRequestClose, selectedUser]);

  const handleUserSelect = useCallback((profile: UserGeneralProfile) => {
    setSelectedUser(profile.id);
  }, []);

  return (
    <SideBar
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      title={t({
        id: "chats-page.select-member",
        defaultMessage: "Select Member",
      })}
      footer={
        <Button
          onClick={handleSubmit}
          disabled={!selectedUser}
          size="large"
          variant="contained"
          fullWidth
        >
          {t({
            id: "common.continue",
            defaultMessage: "Continue",
          })}
        </Button>
      }
    >
      <div className="flex h-full flex-col">
        <Stack gap="10px" sx={{ p: "0 32px" }}>
          <SearchInputUnderline onChange={setSearchQuery} />
        </Stack>
        <div className="flex-1">
          {entity ? (
            <HeadlessUsersList
              fetchParams={{
                entityId: entity.id,
                excludeUsers: myId,
                searchQuery,
              }}
              userRenderer={(user) => (
                <UserListItem
                  entityId={entity.id}
                  userId={user.id}
                  variant="web"
                  mode="radio"
                  isSelected={selectedUser === user.id}
                  onSelect={handleUserSelect}
                  defaultSubTitle="role"
                  highlightText={searchQuery}
                  isDisabled={!hasRoleAccessToModule(user.role, AppModule.chat)}
                  disabledInfo={getUserRolePermissionWarning(AppModule.chat)}
                />
              )}
              // TODO: re-check with UI
              emptyContent={
                <div className="flex h-full w-full items-center justify-center text-center text-sm text-gray-500">
                  {t({
                    id: "chats-page.no-users-found",
                    defaultMessage: "No users found",
                  })}
                </div>
              }
              // TODO: re-check with UI
              loadingContent={
                <div className="flex h-full w-full items-center justify-center">
                  <LoadingSpinner />
                </div>
              }
              virtualized
              segregate={{
                type: "department",
                groupHeaderRenderer: ({ title }) => (
                  <ListHeading>{title}</ListHeading>
                ),
              }}
            />
          ) : null}
        </div>
      </div>
    </SideBar>
  );
};
