import React, { useCallback, useMemo, useState } from "react";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useTranslations } from "@jugl-web/utils";
import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { UserGeneralProfile, useRestApiProvider } from "@jugl-web/rest-api";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { Button, LoadingSpinner } from "@jugl-web/ui-components/cross-platform";
import { ListHeading } from "@jugl-web/ui-components/cross-platform/list/ListHeading";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { useUserRolePermissionWarning } from "@jugl-web/domain-resources/users/hooks/useUserRolePermissionWarning";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";

const AddWorkspaceMemberSideBarContent: React.FC<{
  workspaceId: string;
  onRequestClose: () => void;
}> = ({ workspaceId, onRequestClose }) => {
  const { workspacesApi } = useRestApiProvider();
  const [addWorkspaceParticipants, { isLoading }] =
    workspacesApi.useAddWorkspaceParticipantsMutation();
  const { entity } = useEntity();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { t } = useTranslations();
  const { id: myId } = useMe();
  const getUserRolePermissionWarning = useUserRolePermissionWarning();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const hasSelectedUser = useMemo(
    () => !!selectedUsers.length,
    [selectedUsers]
  );

  const handleSubmit = async () => {
    if (!entity?.id) {
      return;
    }
    const response = await addWorkspaceParticipants({
      participants: selectedUsers,
      entityId: entity?.id,
      id: workspaceId,
    });
    if ("data" in response) {
      onRequestClose();
    }
  };

  const handleUserSelect = useCallback((selectedUser: UserGeneralProfile) => {
    setSelectedUsers((prev) =>
      prev.includes(selectedUser.id)
        ? prev.filter((item) => item !== selectedUser.id)
        : [...prev, selectedUser.id]
    );
  }, []);

  return (
    <>
      <SidebarDrawer.Content>
        <div className="flex h-full flex-col">
          <div className="flex flex-col px-8 pt-6">
            <span className="font-bold">
              {t({
                id: "chats-page.select-members",
                defaultMessage: "Select Members",
              })}
            </span>
            <SearchInput
              className="my-2"
              variant="blank"
              onChange={(e) => setSearchQuery(e.currentTarget.value)}
            />
          </div>
          <div className="flex-1">
            {entity ? (
              <HeadlessUsersList
                fetchParams={{
                  entityId: entity.id,
                  excludeUsers: myId,
                  excludeWorkspaces: workspaceId,
                  preferCache: false,
                  searchQuery,
                }}
                userRenderer={(user) => (
                  <UserListItem
                    entityId={entity.id}
                    userId={user.id}
                    variant="web"
                    mode="check"
                    isSelected={selectedUsers.includes(user.id)}
                    onSelect={handleUserSelect}
                    defaultSubTitle="role"
                    highlightText={searchQuery}
                    isDisabled={
                      !hasRoleAccessToModule(user.role, AppModule.chat)
                    }
                    disabledInfo={getUserRolePermissionWarning(AppModule.chat)}
                  />
                )}
                // TODO: re-check with UI
                emptyContent={
                  <div className="flex h-full w-full items-center justify-center text-center text-sm text-gray-500">
                    No users found
                  </div>
                }
                // TODO: re-check with UI
                loadingContent={
                  <div className="flex h-full w-full items-center justify-center">
                    <LoadingSpinner />
                  </div>
                }
                virtualized
                segregate={{
                  type: "department",
                  groupHeaderRenderer: ({ title }) => (
                    <ListHeading>{title}</ListHeading>
                  ),
                }}
              />
            ) : null}
          </div>
        </div>
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions>
        <Button
          onClick={handleSubmit}
          fullWidth
          isDisabled={isLoading || !hasSelectedUser}
        >
          {t({
            id: "common.add",
            defaultMessage: "Add",
          })}
        </Button>
      </SidebarDrawer.Actions>
    </>
  );
};

const AddWorkspaceMemberSideBar: React.FC<{
  isOpen: boolean;
  onRequestClose: (added?: boolean) => void;
  workspaceId: string;
}> = ({ isOpen, onRequestClose, workspaceId }) => {
  const { t } = useTranslations();

  return (
    <SidebarDrawer
      onClose={onRequestClose}
      isOpen={isOpen}
      title={t({
        id: "chats-page.select-member",
        defaultMessage: "Select Member",
      })}
    >
      <AddWorkspaceMemberSideBarContent
        workspaceId={workspaceId}
        onRequestClose={() => onRequestClose(true)}
      />
    </SidebarDrawer>
  );
};

export default AddWorkspaceMemberSideBar;
