import React, { useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import OutlinedInput, {
  outlinedInputClasses,
} from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from "@mui/material/SvgIcon";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as SearchIcon } from "@web-src/images/icons/search.svg";
import ChatListItem from "@web-src/features/chats/components/ChatListItem";
import { CreateWorkspaceSideBar } from "@web-src/features/workspaces/components/CreateWorkspaceSideBar/CreateWorkspaceSideBar";
import { styled } from "@mui/styles";
import Loader from "@web-src/components/Loader";
import useDebounce from "@web-src/hooks/useDebounce";
import { useTranslations } from "@jugl-web/utils";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  Button,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { OnboardingProgressBar } from "@web-src/modules/preferences/components/OnboardingProgressBar";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { ReactComponent as CreateGroupIcon } from "./icons/create-group.svg";
import { ReactComponent as CreatePersonalChatIcon } from "./icons/create-personal-chat.svg";
import CreatePersonalChatSidebar from "../CreatePersonalChatSidebar";
import useConversations from "../../hooks/useConversations";
import useActiveChat from "../../hooks/useActiveChat";

const ChatList = () => {
  const { subscriptionRequiredAction } = useEntityProvider();
  const { conversations, isConversationsLoading } = useConversations();
  const { setActiveChat, chat: activeChat } = useActiveChat();
  const [createWorkspaceModalOpened, setCreateWorkspaceModalOpened] =
    useState<boolean>(false);
  const [createPersonalChatModalOpened, setCreatePersonalChatModalOpened] =
    useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const handleSearch: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = ({ currentTarget: { value } }) => {
    setSearchQuery(value);
  };
  const { t } = useTranslations();
  const { openSpotlight, openedSpotlight, isOnboardingActive } =
    useOnboarding();
  const { hasEmployeeLikeRole } = useUserRole();

  const visibleConversations = useMemo(() => {
    if (!debouncedSearchQuery) {
      return conversations;
    }
    return conversations.filter(
      (item) =>
        item.data?.title
          ?.toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase()) ||
        item.data?.id
          ?.toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
    );
  }, [debouncedSearchQuery, conversations]);

  return (
    <Wrapper>
      <HeadingWrapper>
        <Typography variant="title3">
          {t({ id: "chats-page.all-chats", defaultMessage: "All Chats" })}
        </Typography>
        {!hasEmployeeLikeRole && (
          <SpotlightTooltip
            isOpen={openedSpotlight === "chat"}
            placement="bottom"
            tip={t({
              id: "edit-profile-page.create-chat-onboarding-hint",
              defaultMessage: "Tap here to Start Your first Chat ✨",
            })}
            showButton={false}
            renderTrigger={({ ref, props }) => (
              <div ref={ref} {...props}>
                <Menu
                  placement="right-end"
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger
                      as={PlainButton}
                      ref={triggerRef}
                      className="bg-primary h-8 w-8 rounded-lg"
                      onClick={() => openSpotlight(undefined)}
                    >
                      <AddIcon className="text-white" />
                    </Trigger>
                  )}
                  onSelect={(_item, _event, close) => close()}
                  sections={[
                    [
                      {
                        id: "new-chat",
                        label: t({
                          id: "chats-page.new-chat",
                          defaultMessage: "New Chat",
                        }),
                        onSelect: subscriptionRequiredAction(
                          setCreatePersonalChatModalOpened.bind(null, true)
                        ),
                        icon: <CreatePersonalChatIcon />,
                      },
                      {
                        id: "create-group",
                        label: t({
                          id: "chats-page.create-group",
                          defaultMessage: "Create Group",
                        }),
                        onSelect: subscriptionRequiredAction(
                          setCreateWorkspaceModalOpened.bind(null, true)
                        ),
                        icon: <CreateGroupIcon />,
                      },
                    ],
                  ]}
                />
              </div>
            )}
            onDismiss={() => openSpotlight(undefined)}
          />
        )}
      </HeadingWrapper>
      <HeadingWrapper>
        <OutlinedInput
          onChange={handleSearch}
          value={searchQuery}
          size="small"
          placeholder={t({
            id: "common.search-with-ellipsis",
            defaultMessage: "Search...",
          })}
          sx={{
            width: "100%",
            fontSize: "16px",
            height: "40px",
            backgroundColor: "primary.50",
            borderRadius: "8px",
            color: "grey.A100",
            [`.${outlinedInputClasses.notchedOutline}`]: {
              border: "none",
            },
          }}
          startAdornment={
            <InputAdornment position="start">
              <SvgIcon component={SearchIcon} sx={{ width: "18px" }} />
            </InputAdornment>
          }
          aria-describedby="Search..."
          inputProps={{
            "aria-label": "search",
          }}
        />
      </HeadingWrapper>
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
        }}
      >
        {isConversationsLoading ? (
          <Loader />
        ) : (
          <div className="flex h-full flex-col">
            <div className="h-full overflow-y-auto">
              <Stack
                divider={
                  <Box
                    sx={{ height: "1px", width: "100%", bgcolor: "grey.100" }}
                  />
                }
              >
                {visibleConversations?.map((item) => (
                  <ChatListItem
                    key={item.id}
                    active={activeChat?.id === item.id}
                    onClick={setActiveChat?.bind(null, item.id, true)}
                    chat={item.data}
                    searchChat={searchQuery}
                  />
                ))}
                {conversations.length === 1 && !searchQuery && (
                  <div className="mx-auto mt-40 flex w-full max-w-[340px] flex-col items-center justify-center gap-2 p-5 text-center">
                    <span className="text-dark font-secondary text-lg font-medium leading-[27px]">
                      {t({
                        id: "chats-page.welcome-to-chats",
                        defaultMessage: "Welcome to Chats! 👋",
                      })}
                    </span>
                    <span className="text-grey font-secondary text-sm leading-[21px]">
                      {t({
                        id: "chats-page.chat-list-notifications-description",
                        defaultMessage:
                          "You will get a notification once you receive your first message",
                      })}
                    </span>
                  </div>
                )}
                {searchQuery && visibleConversations.length === 0 && (
                  <div className="mx-auto mt-40 flex w-full max-w-[240px] flex-col items-center justify-center gap-2.5 p-5 text-center">
                    <span className="text-dark font-secondary text-lg font-medium leading-4">
                      {t({
                        id: "common.no-results",
                        defaultMessage: "No results",
                      })}
                    </span>
                    <span className="font-secondary text-sm leading-[140%] text-[#828282]">
                      {t({
                        id: "chats-page.no-chat-was-found",
                        defaultMessage:
                          "No Chat was found for your search request",
                      })}
                    </span>
                    <Button
                      className="mt-[22px] h-10"
                      onClick={() => setSearchQuery("")}
                    >
                      {t({
                        id: "common.clear-search",
                        defaultMessage: "Clear search",
                      })}
                    </Button>
                  </div>
                )}
              </Stack>
            </div>
            {isOnboardingActive && <OnboardingProgressBar />}
          </div>
        )}
      </Box>
      {createWorkspaceModalOpened && (
        <CreateWorkspaceSideBar
          isOpen
          onRequestClose={setCreateWorkspaceModalOpened.bind(null, false)}
        />
      )}
      <CreatePersonalChatSidebar
        isOpen={createPersonalChatModalOpened}
        onRequestClose={setCreatePersonalChatModalOpened.bind(null, false)}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  width: "35%",
  height: "100%",
  minWidth: "250px",
  maxWidth: "460px",
  display: "flex",
  flexDirection: "column",
  borderRight: `1px solid ${theme.palette.grey["200"]}`,
  position: "relative",
  boxShadow: "2px 0px 3px rgba(0, 0, 0, 0.05)",
  zIndex: 100,
}));

const HeadingWrapper = styled(Box)(({ theme }) => ({
  height: "80px",
  padding: "0 32px",
  borderBottom: `1px solid ${theme.palette.grey["200"]}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export default ChatList;
