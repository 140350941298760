import React, { useCallback, useMemo, useState } from "react";
import { useToast, useTranslations } from "@jugl-web/utils";
import {
  PlainButton,
  Menu,
  ConfirmationPopup,
} from "@jugl-web/ui-components/cross-platform";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { ChatItem } from "@web-src/features/chats/types";
import { useNotifications } from "@web-src/modules/notifications/providers/NotificationsProvider";
import useConversations from "@web-src/features/chats/hooks/useConversations";
import { useRestApiProvider } from "@jugl-web/rest-api";
import UpdateWorkspaceSideBar from "@web-src/features/workspaces/components/UpdateWorkspaceSideBar";
import { useUserRole } from "@web-src/modules/common/hooks/useUserRole";
import { ReactComponent as ThreeDotsVertical } from "./assets/three-dots-vertical.svg";
import { ReactComponent as LeaveIcon } from "./assets/leave.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as NotificationsMuteAllExceptMentionsIcon } from "./assets/notifications_mute_all_except_mentions.svg";
import { ReactComponent as NotificationsMuteAllIcon } from "./assets/notifications_mute_all.svg";
import { ReactComponent as NotificationsMuteNoneIcon } from "./assets/notifications_mute_none.svg";
import { ReactComponent as EditGroupIcon } from "./assets/edit-group.svg";

export const GroupChatMoreMenu: React.FC<{ chat: ChatItem }> = ({ chat }) => {
  const { t } = useTranslations();
  const { getChatNotificationsPreferences, setChatNotificationsPreferences } =
    useNotifications();
  const chatNotificationPreferences = useMemo(
    () => chat?.id && getChatNotificationsPreferences(chat?.id),
    [chat?.id, getChatNotificationsPreferences]
  );
  const { entity } = useEntity();
  const { toast } = useToast({ variant: "web" });
  const [deleteChatConfirmationIsOpen, setDeleteChatConfirmationIsOpen] =
    useState(false);
  const [leaveGroupConfirmationIsOpen, setLeaveGroupConfirmationIsOpen] =
    useState(false);
  const meId = useSelector(selectUserId);
  const { workspacesApi } = useRestApiProvider();
  const [deleteWorkspaceParticipant] =
    workspacesApi.useDeleteWorkspaceParticipantMutation();
  const { deleteGroupConversation } = useConversations();
  const { hasEmployeeLikeRole } = useUserRole();

  const [editWorkspaceIsOpen, setEditWorkspaceIsOpen] = useState(false);

  const handleLeaveWorkspace = useCallback(async () => {
    setLeaveGroupConfirmationIsOpen(false);
    if (!chat?.id || !entity?.id || !meId) {
      return;
    }
    const resp = await deleteWorkspaceParticipant({
      id: chat?.id,
      entityId: entity?.id,
      userId: meId,
    });
    if ("data" in resp) {
      toast(
        t({
          id: "chats-group.left-group",
          defaultMessage: "Left the group",
        })
      );
    }
  }, [deleteWorkspaceParticipant, chat?.id, entity?.id, meId, toast, t]);

  const handleDeleteWorkspace = useCallback(async () => {
    if (!chat?.id) {
      return;
    }
    setDeleteChatConfirmationIsOpen(false);
    try {
      await deleteGroupConversation(chat.id);
      toast(
        t(
          {
            id: "chats-page.group-was-deleted",
            defaultMessage: "Group {groupName} was deleted",
          },
          {
            groupName: chat?.title,
          }
        )
      );
    } catch {
      // TODO: error handling
    }
  }, [deleteGroupConversation, chat.id, chat?.title, toast, t]);

  const $notificationsIcon = useMemo(() => {
    switch (chatNotificationPreferences) {
      case "all":
        return <NotificationsMuteAllIcon />;
      case "all_except_mentions":
        return <NotificationsMuteAllExceptMentionsIcon />;
      case "none":
      default:
        return <NotificationsMuteNoneIcon />;
    }
  }, [chatNotificationPreferences]);

  return (
    <>
      <Menu
        placement="bottom-end"
        renderTrigger={({ Trigger, triggerRef }) => (
          <Trigger
            as={PlainButton}
            ref={triggerRef}
            onClick={(e) => e.stopPropagation()}
          >
            <PlainButton className="bg-grey-100 h-10 w-10 rounded-lg hover:brightness-90">
              <ThreeDotsVertical />
            </PlainButton>
          </Trigger>
        )}
        sections={[
          [
            {
              id: "update-group-info",
              value: "none",
              isHidden: hasEmployeeLikeRole,
              label: t({
                id: "chats-page.edit-group-info",
                defaultMessage: "Edit Group Info",
              }),
              icon: <EditGroupIcon />,
              onSelect: (e, close) => {
                setEditWorkspaceIsOpen(true);
                close();
              },
            },
            {
              id: "2",
              label: t({
                id: "chats-page.mute-chat",
                defaultMessage: "Mute chat",
              }),
              icon: $notificationsIcon,
              submenu: {
                placement: "left",
                sections: [
                  [
                    {
                      id: "none",
                      isSelected: chatNotificationPreferences === "none",
                      value: "none",
                      label: t({
                        id: "chats-page.unmute",
                        defaultMessage: "Unmute",
                      }),
                      toggle: "radio",
                      icon: <NotificationsMuteNoneIcon />,
                      onSelect: (e, close) => {
                        if (!chat?.id) {
                          return;
                        }
                        setChatNotificationsPreferences(
                          chat.id,
                          chat.type,
                          "none"
                        );
                        close();
                      },
                    },
                    {
                      id: "all",
                      isSelected: chatNotificationPreferences === "all",
                      value: "all",
                      label: t({
                        id: "chats-page.mute",
                        defaultMessage: "Mute",
                      }),
                      toggle: "radio",
                      icon: <NotificationsMuteAllIcon />,
                      onSelect: (e, close) => {
                        if (!chat?.id) {
                          return;
                        }
                        setChatNotificationsPreferences(
                          chat.id,
                          chat.type,
                          "all"
                        );
                        close();
                      },
                    },
                    {
                      id: "all_except_mention",
                      isSelected:
                        chatNotificationPreferences === "all_except_mentions",
                      value: "all_except_mention",
                      label: t({
                        id: "chats-page.notify-just-mentions",
                        defaultMessage: "Notify just @mentions",
                      }),
                      toggle: "radio",
                      icon: <NotificationsMuteAllExceptMentionsIcon />,
                      onSelect: (e, close) => {
                        if (!chat?.id) {
                          return;
                        }
                        setChatNotificationsPreferences(
                          chat.id,
                          chat.type,
                          "all_except_mentions"
                        );
                        close();
                      },
                    },
                  ],
                ],
              },
            },
            {
              id: "0",
              label: t({
                id: "chats-page.leave-group",
                defaultMessage: "Leave group",
              }),
              labelClassName: "text-[#EA5353]",
              icon: <LeaveIcon />,
              onSelect: () => {
                setLeaveGroupConfirmationIsOpen(true);
              },
            },
            {
              id: "1",
              isHidden: hasEmployeeLikeRole,
              label: t({
                id: "chats-page.delete-chat",
                defaultMessage: "Delete group",
              }),
              labelClassName: "text-[#EA5353]",
              icon: <DeleteIcon />,
              onSelect: () => {
                setDeleteChatConfirmationIsOpen(true);
              },
            },
          ],
        ]}
      />

      <ConfirmationPopup
        variant="web"
        isOpen={deleteChatConfirmationIsOpen}
        onRequestClose={() => setDeleteChatConfirmationIsOpen(false)}
        title={t({
          id: "chats-page.delete-group",
          defaultMessage: "Delete group",
        })}
        message={t(
          {
            id: "chats-page.delete-group-message",
            defaultMessage:
              "Do you really want to delete the {groupName} group?",
          },
          {
            groupName: (
              <span className="text-primary font-bold">{chat?.title}</span>
            ),
          }
        )}
        hasCancelButton
        buttons={[
          {
            label: t({
              id: "common.delete",
              defaultMessage: "Delete",
            }),
            color: "tertiary",
            onClick: handleDeleteWorkspace,
          },
        ]}
      />

      <ConfirmationPopup
        variant="web"
        isOpen={leaveGroupConfirmationIsOpen}
        onRequestClose={() => setLeaveGroupConfirmationIsOpen(false)}
        title={t({
          id: "chats-page.leave-group",
          defaultMessage: "Leave group",
        })}
        message={t({
          id: "chats-page.leave-group-message",
          defaultMessage: "Do you really want to leave this group?",
        })}
        hasCancelButton
        buttons={[
          {
            label: t({
              id: "common.leave",
              defaultMessage: "Leave",
            }),
            color: "tertiary",
            onClick: handleLeaveWorkspace,
          },
        ]}
      />

      {chat.id && editWorkspaceIsOpen && (
        <UpdateWorkspaceSideBar
          workspaceId={chat.id}
          isOpen
          onRequestClose={() => setEditWorkspaceIsOpen(false)}
          title={chat.title}
          img={chat.img || undefined}
        />
      )}
    </>
  );
};
