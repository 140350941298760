export enum InventoryItemCategory {
  item = "item",
  service = "service",
}

export enum InventoryItemUnit {
  pcs = "pcs",
  qty = "qty",
  kg = "kg",
  dz = "dz",
  cm = "cm",
  in = "in",
  ft = "ft",
  g = "g",
  km = "km",
  m = "m",
  lb = "lb",
  mg = "mg",
}

export enum InventoryItemPriceUnit {
  usd = "usd",
}

export enum InventoryItemWeightUnit {
  kg = "kg",
  g = "g",
  lb = "lb",
  mg = "mg",
  t = "t",
}

export enum InventoryItemDimensionUnit {
  cm = "cm",
  in = "in",
  mm = "mm",
  m = "m",
  ft = "ft",
}

export type InventoryItemParams = {
  inventoryItemName: string;
  category: InventoryItemCategory;
  unit: InventoryItemUnit | null;
  price: {
    value: string;
  };
  files: File[];
  description: string;
  dimensions: {
    value: {
      width: string;
      height: string;
      depth: string;
    };
    unit: InventoryItemDimensionUnit;
  } | null;
  weight: {
    value: string;
    unit: InventoryItemWeightUnit;
  } | null;
  sku: string;
  upc: string;
  mpn: string;
  ean: string;
  isbn: string;
  stock: number;
  reorder_point: number;
  warehouse: {
    id: string;
    name: string;
  } | null;
  isTrackInventory: boolean;
  reorderPoint: number;
};

export type InventoryItem = {
  category: InventoryItemCategory;
  desc: string;
  id: string;
  img: {
    id: string;
    order: number | null;
    path: string;
  }[];
  info: {
    dim: string | null;
    weight: string | null;
    sku: string | null;
    upc: string | null;
    mpn: string | null;
    ean: string | null;
    isbn: string | null;
  };
  name: string;
  price: string;
  stock: {
    stock: number;
    notes: string;
    reorder_point: number | null;
    in_process: number;
  };
  unit: InventoryItemUnit;
  warehouse: {
    id: string;
    name: string;
  };
};

export type DiscountItem = {
  id: string;
  name: string;
  discount: number;
  desc: string;
  status: "active" | "inactive";
};

export type WarehouseItem = {
  status: "active" | "inactive";
  email: string;
  name: string;
  mobile: string;
  id: string;
  address: {
    country: string;
    state: string;
    city: string;
    zipcode: string;
    address: string;
  };
};

export type InventoryLog =
  | {
      action: "created";
      action_by: string;
      info?: {
        price: number;
        stock_on_hand: number | null;
      };
      inserted_at: string;
    }
  | {
      action: "order_placed";
      action_by: string | null;
      info: {
        available: number;
        price: number;
        qty: number;
        stock_on_hand: number;
        processing_order: number;
        task_name: string;
        task_id: string;
        unit: string;
      };
      inserted_at: string;
    }
  | {
      action: "stock_info";
      action_by: string;
      info: {
        prev_price?: number;
        updated_price?: number;
        available_qty?: number;
        adjusted_qty?: number;
        stock_on_hand?: number;
        processing_order: number;
        warehouse?: string | null;
      };
      inserted_at: string;
    };
