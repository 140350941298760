import { TableGrid } from "@jugl-web/ui-components";
import React from "react";
import { priceDisplay, useTranslations, cx } from "@jugl-web/utils";
import { OrderResponseInvoice } from "@jugl-web/rest-api/orders";
import { InventoryItemCategory } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { OrderNameTile } from "./components/OrderNameTile";
import { SectionHeader } from "../SectionHeader";
import { ReactComponent as ItemsIcon } from "./assets/items.svg";
import { OrderCategoryTile } from "./components/OrderCategoryTile";

export const OrderItemsTable: React.FC<{
  invoice: OrderResponseInvoice;
}> = ({ invoice }) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();

  return (
    <div>
      <SectionHeader
        icon={<ItemsIcon />}
        title={t({
          id: "tasks-page.items-services",
          defaultMessage: "Items / Services",
        })}
      />
      <div className="overflow-x-auto">
        <TableGrid
          unstyled
          data={invoice.items}
          className="font-secondary mt-[18px] min-w-fit rounded-xl border border-solid border-[#EEF2F5]"
          headerCellClassName="px-4 py-3 text-xs text-dark-700 font-normal leading-[140%] border-0 border-r border-solid border-[#EEF2F5]"
          cellClassName="px-4 text-dark-800 font-normal flex items-center text-sm py-3 border-0 border-t border-r border-solid border-[#EEF2F5] break-all leading-[140%] tracking-[0.14px] hover:bg-grey-200"
          rowHoverClassName="bg-grey-100"
          columns={[
            {
              title: t({
                id: "tasks-page.item-service-name",
                defaultMessage: "Item / service name",
              }),
              className: "min-w-[280px]",
              grow: true,
              content: ({ name, img, discount }) => (
                <OrderNameTile
                  title={name}
                  img={
                    img?.find((el) => el.order === 1)?.path || img?.[0]?.path
                  }
                  discount={discount?.discount}
                />
              ),
            },
            {
              title: t({
                id: "tasks-page.category",
                defaultMessage: "Category",
              }),
              className: "p-0.5 min-w-[90px]",
              grow: true,
              content: ({ category }) => (
                <OrderCategoryTile category={category} />
              ),
            },
            {
              title: t({
                id: "tasks-page.description",
                defaultMessage: "Description",
              }),
              className: "min-w-[120px]",
              grow: true,
              content: ({ desc }) => <>{desc}</>,
            },
            {
              title: t({
                id: "tasks-page.quantity",
                defaultMessage: "Quantity",
              }),
              className: "min-w-[90px]",
              grow: true,
              content: ({ qty, unit, category }) => (
                <span>
                  {qty} {category === InventoryItemCategory.item && unit}
                </span>
              ),
            },
            {
              title: t({
                id: "tasks-page.cost",
                defaultMessage: "Cost",
              }),
              className: "border-r-0 min-w-[140px]",
              headerClassName: "border-r-0",
              grow: true,
              content: ({ total, category, unit, qty, discount }) => (
                <span>
                  <span
                    className={cx({
                      "text-gradients-danger": discount?.discount,
                    })}
                  >
                    {priceDisplay((total / qty) * 100, entity.currency)}{" "}
                  </span>
                  <span className="text-grey-700">{`/${
                    category === InventoryItemCategory.service
                      ? t({
                          id: "common.service",
                          defaultMessage: "service",
                        })
                      : unit
                  }`}</span>
                </span>
              ),
            },
          ]}
        />
      </div>
      <div className="text-dark-800 font-secondary mt-4 grid grid-cols-5 font-medium leading-[140%] tracking-[0.16px]">
        <div className="col-span-3" />
        <span className="px-4">
          {t({
            id: "tasks-page.total",
            defaultMessage: "Total:",
          })}
        </span>
        <span className="flex items-center px-4">
          {priceDisplay(invoice.total * 100, entity.currency)}
        </span>
      </div>
    </div>
  );
};
