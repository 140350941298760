import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import useActiveChat from "@web-src/features/chats/hooks/useActiveChat";
import ChatInput, {
  ChatInputHandle,
  ChatInputParams,
} from "@web-src/features/chats/components/ChatInput";
import {
  ChatMessage,
  ChatMessageActionType,
  ChatMessageType,
  ChatType,
} from "@web-src/features/chats/types";
import { usePrevious, cx, useTranslations } from "@jugl-web/utils";
import { useDispatch, useSelector } from "react-redux";
import ChatMessageSideBar from "@web-src/features/workspaces/components/ChatMessageSideBar";
import PeopleInfoSidebar from "@web-src/features/people/components/PeopleInfoSidebar";
import {
  useEntityProvider,
  useEntitySelectedProvider,
} from "@web-src/modules/entities/providers/EntityProvider";
import { ChatInfoSideBar } from "@web-src/modules/chats/components/ChatInfoSideBar/ChatInfoSideBar";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import ChatHeader from "../../../../modules/chats/components/ChatHeader";
import useSendMessage from "../../hooks/useSendMessage";
import useConversations from "../../hooks/useConversations";
import ChatMessagesWindow from "../ChatMessagesWindow";
import useMessageActions from "../../hooks/useMessageActions";
import MessageActionItem from "../MessageActionItem";
import useEditMessage from "../../hooks/useEditMessage";
import ForwardSideBar from "../ForwardSideBar";
import DeleteSideBar from "../DeleteSideBar";
import { ChatMessagesWindowHandle } from "../ChatMessagesWindow/ChatMessagesWindow";
import {
  selectOpenedUserProfile,
  setOpenedUserProfile,
  selectSearchSidebarIsOpen,
} from "../../chatsSlice";
import { ChatMessageSearchSidebar } from "../ChatMessageSearchSidebar";
import { ChatMessageProvider } from "../../providers/ChatMessageProvider";

const Chat: React.FC = () => {
  const dispatch = useDispatch();
  const { entityId } = useEntitySelectedProvider();
  const { t } = useTranslations();

  const openedUserProfile = useSelector(selectOpenedUserProfile);
  const searchSidebarIsOpen = useSelector(selectSearchSidebarIsOpen);
  const { chat, isParticipant } = useActiveChat();
  const { subscriptionInfo } = useEntityProvider();
  const { addNewMessage } = useConversations();
  const sendMessage = useSendMessage();
  const editMessage = useEditMessage();
  const {
    openedChatMessage,
    closeMessageInfo,
    cancelMessageAction,
    messageAction,
  } = useMessageActions(chat?.id);
  const [selectedMessages, setSelectedMessages] = useState<ChatMessage[]>([]);
  const [chatInfoSideBarIsOpen, setChatInfoSideBarIsOpen] = useState(false);
  const $inputRef = useRef<ChatInputHandle>(null);
  const $chatWindowRef = useRef<ChatMessagesWindowHandle>(null);
  const [isSending, setIsSending] = useState<boolean>();

  const handleCancelMessageAction = useCallback(() => {
    if (messageAction?.type === ChatMessageActionType.edit) {
      $inputRef.current?.clear();
    }
    cancelMessageAction();
  }, [cancelMessageAction, messageAction?.type]);

  useEffect(() => {
    if (
      messageAction?.type === ChatMessageActionType.edit &&
      messageAction?.message
    ) {
      $inputRef.current?.setValue(
        messageAction.message.payload?.body ||
          messageAction.message.payload?.attachments?.[0]?.caption ||
          "",
        messageAction.message
      );
    }
    if (
      messageAction?.type &&
      [ChatMessageActionType.edit, ChatMessageActionType.reply].includes(
        messageAction?.type
      )
    ) {
      $inputRef.current?.focus();
    }
  }, [messageAction?.message, messageAction?.type]);

  const handleSendClick: ChatInputParams["onSubmit"] = useCallback(
    async ({ body, file, gif, attachmentType, conference }) => {
      if (!chat?.id) {
        return;
      }
      if (
        messageAction?.message &&
        messageAction?.type === ChatMessageActionType.edit
      ) {
        setIsSending(true);
        editMessage({
          msgId: messageAction.message.msg_id,
          body,
          attachment: messageAction.message.payload?.attachments?.[0],
          chatId: chat.id,
        })
          .then(() => {
            cancelMessageAction();
            $inputRef?.current?.clear();
          })
          .finally(() => {
            setIsSending(false);
          });
        return;
      }
      sendMessage({
        to: chat.id,
        type:
          chat.type === ChatType.muc
            ? ChatMessageType.muc
            : ChatMessageType.chat,
        body,
        file,
        action: messageAction,
        gif,
        attachmentType,
        conference,
      }).then((item) => {
        if (item.type === "success") {
          if (!chat.id) {
            return;
          }
          addNewMessage?.({
            id: chat.id,
            message: item.message as ChatMessage,
            outgoing: true,
          });
          $chatWindowRef.current?.scrollToBottom();
        }
      });
      if (subscriptionInfo?.actionsAllowed) {
        $inputRef?.current?.clear();
      }
      if (messageAction) {
        cancelMessageAction();
      }
      setTimeout(() => {
        $chatWindowRef.current?.scrollToBottom();
      }, 100);
    },
    [
      addNewMessage,
      cancelMessageAction,
      chat?.id,
      chat?.type,
      editMessage,
      messageAction,
      sendMessage,
      subscriptionInfo,
    ]
  );

  const { profile } = useUserGeneralProfile({
    entityId,
    userId: chat?.id,
    skip: !chat?.id || chat.type !== ChatType.chat,
  });

  const isChatDisabled =
    profile && chat?.type === ChatType.chat
      ? !hasRoleAccessToModule(profile.role, AppModule.chat)
      : false;

  // re-mount chat component, to make sure there is no left owers from previous chat state
  // TODO: find better solution
  const [chatTemporairlyHidden, setChatTemporairlyHidden] = useState<boolean>();
  const previousActiveChat = usePrevious(chat);
  useEffect(() => {
    if (previousActiveChat && chat?.id !== previousActiveChat?.id) {
      setChatTemporairlyHidden(true);
      setTimeout(() => {
        setChatTemporairlyHidden(false);
      }, 0);
    }
  }, [chat, previousActiveChat]);

  const closeUserProfile = useCallback(() => {
    dispatch(setOpenedUserProfile());
  }, [dispatch]);

  const onChatHeaderClick = useMemo(
    () =>
      chat?.id &&
      (chat?.type === ChatType.chat ||
        (chat?.type === ChatType.muc && isParticipant))
        ? () => setChatInfoSideBarIsOpen(true)
        : undefined,
    [chat, isParticipant, setChatInfoSideBarIsOpen]
  );

  const showChatSideBar = useMemo(
    () =>
      chatInfoSideBarIsOpen &&
      chat?.id &&
      (chat?.type === ChatType.chat ||
        (chat?.type === ChatType.muc && isParticipant)),
    [chatInfoSideBarIsOpen, chat, isParticipant]
  );

  if (!chat) {
    return null;
  }
  return (
    <>
      <div className="flex-1 overflow-x-auto">
        <div className="relative flex h-full flex-1 flex-col">
          <ChatHeader onClick={onChatHeaderClick} />
          <div className="relative w-full flex-1">
            {chat && !chatTemporairlyHidden ? (
              <ChatMessagesWindow
                ref={$chatWindowRef}
                chat={chat}
                disableMessageActions={chat?.type === "muc" && !isParticipant}
                selectedMessages={selectedMessages}
                setSelectedMessages={setSelectedMessages}
                messageAction={messageAction}
              />
            ) : null}
          </div>
          {!chat.deleted && (chat.type === ChatType.chat || isParticipant) && (
            <div className="border-t-1 border-0 border-t border-solid border-gray-200 px-4 py-2 shadow">
              {messageAction?.type === ChatMessageActionType.edit ||
              messageAction?.type === ChatMessageActionType.reply ? (
                <ChatMessageProvider message={messageAction.message}>
                  <MessageActionItem
                    message={messageAction.message}
                    action={messageAction.type}
                    onCancel={handleCancelMessageAction}
                  />
                </ChatMessageProvider>
              ) : undefined}
              {chat?.id && !isChatDisabled && (
                <ChatInput
                  chatId={chat.id}
                  onSubmit={handleSendClick}
                  ref={$inputRef}
                  isLoading={isSending}
                  disableAttachments={
                    messageAction?.type === ChatMessageActionType.edit
                  }
                />
              )}
              {isChatDisabled && (
                <div className="flex min-h-[69px] w-full items-center justify-center px-5 py-2 text-center">
                  <span className="text-dark font-secondary text-sm leading-[21px]">
                    {t(
                      {
                        id: "chats-page.role-permission-chat-no-access-description",
                        defaultMessage:
                          "📛 Due to <highlightedText>Roles Permissions</highlightedText>, this user don't have access to the Chats and cant receive any New Messages or Calls 🙅‍♀️",
                      },
                      {
                        highlightedText: (text: (string | JSX.Element)[]) => (
                          <span className="text-primary-800 font-semibold">
                            {text}
                          </span>
                        ),
                      }
                    )}
                  </span>
                </div>
              )}
            </div>
          )}
          {openedChatMessage && (
            <ChatMessageSideBar
              message={openedChatMessage}
              isOpen
              workspaceId={chat?.type === ChatType.muc ? chat?.id : undefined}
              onRequestClose={closeMessageInfo}
            />
          )}
        </div>
      </div>
      {showChatSideBar && !chat.deleted && (
        <div className={cx({ hidden: searchSidebarIsOpen === true })}>
          <ChatInfoSideBar onClose={() => setChatInfoSideBarIsOpen(false)} />
        </div>
      )}
      {openedUserProfile && (
        <PeopleInfoSidebar
          userId={openedUserProfile}
          isOpen
          onClose={closeUserProfile}
        />
      )}
      {messageAction?.type === ChatMessageActionType.delete && (
        <DeleteSideBar
          messages={selectedMessages}
          onRequestClose={() => {
            cancelMessageAction();
            setSelectedMessages([]);
          }}
        />
      )}
      {messageAction?.type === ChatMessageActionType.forward && (
        <ForwardSideBar
          messages={selectedMessages}
          onRequestClose={() => {
            cancelMessageAction();
            setSelectedMessages([]);
          }}
        />
      )}
      {searchSidebarIsOpen && <ChatMessageSearchSidebar />}
      {openedUserProfile && (
        <PeopleInfoSidebar
          userId={openedUserProfile}
          isOpen
          onClose={closeUserProfile}
        />
      )}
    </>
  );
};

export default Chat;
