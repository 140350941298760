import React, { useMemo } from "react";
import { ChatItem } from "@web-src/features/chats/types";
import {
  Checkbox,
  Avatar,
  InteractiveContainer,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { hasRoleAccessToModule } from "@jugl-web/domain-resources/users/utils/hasRoleAccessToModule";
import { Tooltip } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { AppModule } from "@jugl-web/domain-resources/common/types";
import { ReactComponent as InfoIcon } from "./assets/info.svg";

export const ChatListPickerItem: React.FC<{
  chat: ChatItem;
  isSelected: boolean;
  onSelect: (chat: ChatItem) => void;
}> = ({ chat, isSelected, onSelect }) => {
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();

  const { profile } = useUserGeneralProfile({
    entityId,
    userId: chat.id,
    skip: chat.type !== "chat",
  });

  const unknownName = useMemo(() => {
    if (chat.type === "muc") {
      return "Unknown Group";
    }
    return "Unknown User";
  }, [chat]);

  const isDisabled = useMemo(
    () =>
      profile ? !hasRoleAccessToModule(profile.role, AppModule.chat) : false,
    [profile]
  );

  return (
    <InteractiveContainer
      className="flex items-center gap-2 px-8 py-1"
      onClick={onSelect.bind(null, chat)}
      isDisabled={isDisabled}
    >
      <Avatar
        username={chat.title || unknownName}
        imageUrl={chat.img}
        className="h-10 w-10"
      />
      <p className="grow truncate">{chat.title || unknownName}</p>
      {!isDisabled ? (
        <Checkbox readOnly isChecked={isSelected} />
      ) : (
        <Tooltip
          renderTrigger={({ props, ref }) => (
            <PlainButton ref={ref} {...props}>
              <InfoIcon />
            </PlainButton>
          )}
        >
          {t({
            id: "chats-page.role-doesnt-have-permissions-to-chats",
            defaultMessage:
              "Due to Role Permissions, this user doesn’t have access to the Chats 🙅‍♀️",
          })}
        </Tooltip>
      )}
    </InteractiveContainer>
  );
};
