import { UserRole } from "@jugl-web/rest-api";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { ChatMessagePayload, DigestType } from "@web-src/features/chats/types";
import {
  NavigationPage,
  useNavigation,
} from "@web-src/modules/navigation/hooks/useNavigation";
import { isoToLocalDate } from "@web-src/utils/helper";
import React, { useMemo } from "react";
import { ReactComponent as CallIcon } from "./icons/call.svg";
import { ReactComponent as ChatIcon } from "./icons/chat.svg";
import { ReactComponent as CustomerIcon } from "./icons/customer.svg";
import { ReactComponent as DriveIcon } from "./icons/drive.svg";
import { ReactComponent as PeopleIcon } from "./icons/people.svg";
import { ReactComponent as TaskIcon } from "./icons/task.svg";

const typeToBackgroundClasses: Record<DigestType, string> = {
  people: "bg-[#FEF6E2]",
  call: "bg-[#FFE4E4]",
  drive: "bg-primary-50",
  chat: "bg-[#EFF7DF]",
  task: "bg-[#F2E4FB]",
  customer: "bg-[#E5E3FA]",
  task_reminder: "bg-tertiary-100",
};

const typeToIcon: Record<DigestType, JSX.Element> = {
  people: <PeopleIcon />,
  call: <CallIcon />,
  drive: <DriveIcon />,
  chat: <ChatIcon />,
  task: <TaskIcon />,
  customer: <CustomerIcon />,
  task_reminder: <TaskIcon />,
};

const typeToNavigationPage = {
  people: "peoplePeople",
  drive: "driveDrive",
  chat: "chatsChats",
  task: "tasksTasks",
  customer: "customersCustomers",
} satisfies Record<
  Exclude<DigestType, "call" | "task_reminder">,
  NavigationPage
>;

interface DigestBubbleProps {
  title: string;
  date: string;
  type: DigestType;
  reminder?: ChatMessagePayload["task_reminder"];
}

export const DigestBubble: React.FC<DigestBubbleProps> = ({
  title,
  date,
  type,
  reminder,
}) => {
  const { t } = useTranslations();
  const { humanizePastDate } = useFormattedDate();
  const timeStr = useMemo(
    () => humanizePastDate(isoToLocalDate(date)),
    [humanizePastDate, date]
  );
  const { navigateToPage } = useNavigation();
  const { entity } = useEntity();

  const handleClick = () => {
    if (type === "call") {
      return;
    }

    if (type === "task_reminder") {
      if (!reminder) return;
      navigateToPage("taskDetails", { taskId: reminder.id });
      return;
    }

    if (type === "task") {
      navigateToPage("tasksTasks", { boardId: "my" });
      return;
    }

    const targetPage = typeToNavigationPage[type];
    navigateToPage(targetPage);
  };

  return (
    <InteractiveContainer
      className="h-max w-full max-w-[800px] overflow-hidden rounded-xl bg-white p-4 drop-shadow-[0_1px_4px_rgba(0,0,0,0.05)]"
      onClick={handleClick}
      isDisabled={type === "customer" && entity?.role !== UserRole.admin}
    >
      <div className="flex items-center gap-4">
        <div
          className={cx(
            "flex h-8 w-8 shrink-0 items-center justify-center rounded-md",
            typeToBackgroundClasses[type]
          )}
        >
          {typeToIcon[type]}
        </div>
        {type === "task_reminder" ? (
          <span className="overflow-hidden break-words text-sm text-black">
            {t(
              {
                id: "chats-page.task-reminder-message",
                defaultMessage: `<b>Task reminder</b>: "{taskName}" due to complete {remainingTime}`,
              },
              {
                b: (text: (string | JSX.Element)[]) => (
                  <span className="font-medium">{text}</span>
                ),
                taskName: title,
                remainingTime: reminder?.due_in || "",
              }
            )}
          </span>
        ) : (
          <span
            className="overflow-hidden break-words text-sm text-black"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
      </div>
      <div className="text-grey ml-12 mt-2 text-xs">{timeStr}</div>
    </InteractiveContainer>
  );
};
