import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import { Checkbox } from "@jugl-web/ui-components/cross-platform/Checkbox";
import { Radio } from "@jugl-web/ui-components/cross-platform/Radio";
import { AppVariant, cx, useTranslations } from "@jugl-web/utils";
import { FC, memo, useCallback, useMemo } from "react";
import { UserGeneralProfile } from "@jugl-web/rest-api";
import Highlighter from "react-highlight-words";
import { Menu, PlainButton } from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components";
import { ReactComponent as MoreIcon } from "./images/more.svg";
import { ReactComponent as InfoIcon } from "./images/info.svg";
import { useUserGeneralProfile } from "../../hooks/useUserGeneralProfile";
import { useUserRoleName } from "../../hooks/useUserRoleName";

interface UserListItemParams {
  entityId: string;
  variant: AppVariant;
  userId: string;
  isMe?: boolean;
  className?: string;
  mode?: "display" | "check" | "radio";
  isSelected?: boolean;
  isDisabled?: boolean;
  onSelect?: (profile: UserGeneralProfile) => void;
  subTitle?: string;
  defaultSubTitle?: "department" | "role";
  highlightText?: string;
  options?: {
    text: string;
    onClick: (profile: UserGeneralProfile) => void;
  }[];
  disabledInfo?: string;
}

export const UserListItem: FC<UserListItemParams> = memo(
  ({
    variant,
    userId,
    isSelected,
    isMe,
    className,
    onSelect,
    entityId,
    mode = "display",
    subTitle,
    defaultSubTitle = "department",
    highlightText,
    isDisabled,
    disabledInfo,
    options,
  }) => {
    const { profile } = useUserGeneralProfile({ userId, entityId });
    const isMobile = variant === "mobile";
    const isAvatarSelected = isSelected && isMobile;
    const { t } = useTranslations();
    const { getRoleName } = useUserRoleName();
    const isNotRegistered = profile && !profile?.hasProfile;

    const handleSelect = useCallback(
      (selectedProfile: UserGeneralProfile) => {
        if (mode === "display") {
          return;
        }
        onSelect?.(selectedProfile);
      },
      [mode, onSelect]
    );

    const title = useMemo(
      () =>
        `${profile?.displayName || ""}${isMe ? " (Me)" : ""}${
          isNotRegistered ? ` (Not registered)` : ""
        }`,
      [isMe, isNotRegistered, profile?.displayName]
    );

    const subTitleText = useMemo(() => {
      if (subTitle) {
        return subTitle;
      }
      if (defaultSubTitle === "department") {
        return (
          profile?.department?.name ||
          t({
            id: "common.no-department",
            defaultMessage: "No department",
          })
        );
      }
      return getRoleName(profile?.role);
    }, [
      getRoleName,
      defaultSubTitle,
      profile?.department?.name,
      profile?.role,
      subTitle,
      t,
    ]);

    return (
      <button
        type="button"
        className={cx(
          "flex h-[72px] w-full items-center justify-between border-none",
          mode !== "display" && "cursor-pointer",
          isMobile ? "px-4" : "px-8",
          isSelected ? "bg-primary-50" : "bg-white",
          isNotRegistered && "opacity-50",
          isDisabled && "cursor-default",
          className
        )}
        onClick={profile ? () => handleSelect(profile) : undefined}
        disabled={isDisabled}
      >
        <div className="flex w-full items-center gap-3">
          <Avatar
            size="lg"
            username={profile?.displayName || ""}
            imageUrl={profile?.avatar}
            isSelected={isAvatarSelected}
            key={profile?.id}
          />
          <div className="flex-1 overflow-hidden text-left">
            <div
              className={cx(
                "text-dark mb-1 block text-left text-base font-semibold leading-3",
                isDisabled && "text-dark-400"
              )}
            >
              {highlightText ? (
                <Highlighter
                  autoEscape
                  highlightClassName="text-primary"
                  highlightTag="span"
                  textToHighlight={title}
                  searchWords={[highlightText]}
                />
              ) : (
                title
              )}
            </div>
            <div
              className={cx(
                "leading-base overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm font-normal",
                {
                  capitalize: !subTitle && defaultSubTitle === "role",
                  "text-secondary":
                    !subTitle &&
                    defaultSubTitle === "department" &&
                    profile?.department?.name,
                  "text-grey":
                    subTitle ||
                    (defaultSubTitle === "department" &&
                      !profile?.department?.name),
                  "text-primary": defaultSubTitle === "role",
                  "text-dark-300": isDisabled,
                }
              )}
            >
              {subTitleText}
            </div>
          </div>
          {mode !== "display" && (
            <>
              {!isDisabled ? (
                <div className="w-[18px]">
                  {mode === "radio" ? (
                    <Radio readOnly tabIndex={-1} isChecked={isSelected} />
                  ) : (
                    <Checkbox readOnly tabIndex={-1} isChecked={isSelected} />
                  )}
                </div>
              ) : disabledInfo ? (
                <Tooltip
                  renderTrigger={({ props, ref }) => (
                    <PlainButton ref={ref} {...props}>
                      <InfoIcon />
                    </PlainButton>
                  )}
                >
                  {disabledInfo}
                </Tooltip>
              ) : null}
            </>
          )}
        </div>
        {options && profile ? (
          <Menu
            placement="bottom-start"
            sections={[
              options.map((item, idx) => ({
                id: idx.toString(),
                label: item.text,
                onSelect: () => item.onClick(profile),
              })),
            ]}
            renderTrigger={({ Trigger, triggerRef }) => (
              <Trigger
                as={PlainButton}
                ref={triggerRef}
                className="flex h-8 w-8 items-center justify-center rounded-full transition-all hover:bg-gray-100"
              >
                <MoreIcon />
              </Trigger>
            )}
          />
        ) : null}
      </button>
    );
  }
);
