import { InventoryItemDataModel } from "@jugl-web/rest-api/inventory/models/InventoryItem";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEffect, useState, useCallback, ReactNode } from "react";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { InventoryLogDataDto } from "@jugl-web/rest-api/inventory/models/InventoryLogs";
import { useInView } from "react-intersection-observer";
import { Avatar, LoadingAnimation } from "@jugl-web/ui-components";
import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { priceDisplay, useTranslations, cx } from "@jugl-web/utils";
import { format } from "date-fns";
import { apiUTCToLocalDateTime } from "@jugl-web/utils/date-time/apiUTCToLocalDateTime";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { ReactComponent as ListIcon } from "./assets/list.svg";

const LogParameter = ({
  label,
  value,
  variant,
}: {
  label: string;
  value?: ReactNode | string | null;
  variant?: "blue" | "green" | "red";
}) => {
  if (!value) return null;
  return (
    <div className="font-primary flex items-center gap-1 text-sm">
      <div className="text-dark-600">{label}:</div>
      <div
        className={cx("text-dark-700 truncate pr-1 font-medium", {
          "text-primary-800 bg-primary-500/10 rounded-lg px-1.5  py-0.5":
            variant === "blue",
          "text-secondary-800 bg-secondary-500/10 rounded-lg px-1.5  py-0.5":
            variant === "green",
          "text-tertiary-800  bg-tertiary-500/10 rounded-lg px-1.5  py-0.5":
            variant === "red",
        })}
      >
        {value}
      </div>
    </div>
  );
};

const LogItem = ({
  action,
  action_by,
  info,
  unit,
  inserted_at,
  isService,
}: InventoryLogDataDto & { isService: boolean; unit?: string }) => {
  const { entity } = useEntitySelectedProvider();
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();

  if (action === "created") {
    return (
      <UserGeneralProfile entityId={entity.id} userId={action_by}>
        {({ safeProfile }) => (
          <div className="bg-grey-100 flex w-full gap-3 rounded-[10px] px-4 py-6">
            <div>
              <Avatar
                imageUrl={safeProfile.avatar}
                username={safeProfile.displayName}
              />
            </div>
            <div className="text-dark font-secondary truncate">
              {t(
                {
                  id: "inventory-logs.item-was-created-by-user",
                  defaultMessage:
                    "{type} was created by <medium>{action_by}</medium>",
                },
                {
                  type: isService ? "Service" : "Item",
                  action_by: safeProfile.displayName,
                  medium: (text: (string | JSX.Element)[]) => (
                    <span className="font-medium">{text}</span>
                  ),
                }
              )}
              <div className="flex flex-col gap-1 pt-2">
                {!isService && (
                  <LogParameter
                    value={
                      info?.stock_on_hand?.toString()
                        ? `${info?.stock_on_hand} ${unit || ""}`
                        : null
                    }
                    label={t({
                      id: "inventory-logs.stock-on-hand",
                      defaultMessage: "Stock on Hand",
                    })}
                  />
                )}
                <LogParameter
                  value={
                    info?.price?.toString()
                      ? priceDisplay(info.price * 100, entity.currency)
                      : null
                  }
                  label={t({
                    id: "common.price",
                    defaultMessage: "Price",
                  })}
                />
              </div>
              <div className="text-grey-900 font-primary pt-2 text-xs">
                {inserted_at &&
                  format(apiUTCToLocalDateTime(inserted_at), "MMM d, h:mm a")}
              </div>
            </div>
          </div>
        )}
      </UserGeneralProfile>
    );
  }
  if (action === "order_placed") {
    return (
      <div className="bg-grey-100 flex w-full gap-3 rounded-[10px] px-4 py-6">
        <div className="flex h-[32px] w-[32px] items-center justify-center rounded-full bg-[#F33A9E]">
          <ListIcon />
        </div>
        <div className="text-dark font-secondary flex w-10/12 flex-col gap-2 truncate">
          <div className="truncate">
            {t(
              {
                id: "inventory-logs.new-order-was-placed",
                defaultMessage:
                  "New Order was placed in <link>{taskName}</link>",
              },
              {
                taskName: info.task_name,
                link: (text: (string | JSX.Element)[]) => (
                  <span
                    onClick={() => {
                      if (!info.task_id) {
                        return;
                      }
                      navigateToPage("taskDetails", { taskId: info.task_id });
                    }}
                    className="text-primary-800 cursor-pointer font-medium underline"
                  >
                    {text}
                  </span>
                ),
              }
            )}
          </div>
          <div>
            {isService && (
              <LogParameter
                value={`${info.qty} ${t({
                  id: "inventory-logs.service",
                  defaultMessage: "service",
                })}`}
                label={t({
                  id: "inventory-logs.quantity-of-ordered-services",
                  defaultMessage: "Quantity of Ordered services",
                })}
                variant="blue"
              />
            )}
            <LogParameter
              value={
                info.price?.toString()
                  ? priceDisplay(info.price * 100, entity.currency)
                  : null
              }
              label={
                isService
                  ? t({
                      id: "inventory-logs.price-per-service",
                      defaultMessage: "Price per service",
                    })
                  : t({
                      id: "inventory-logs.price-per-item",
                      defaultMessage: "Price per item",
                    })
              }
            />
            <LogParameter
              value={
                info.price?.toString() && info.qty?.toString()
                  ? priceDisplay(info.price * 100 * info.qty, entity.currency)
                  : null
              }
              label={t({
                id: "inventory-logs.total-cost",
                defaultMessage: "Total Cost",
              })}
            />
            {!isService && (
              <LogParameter
                value={`${info.qty} ${unit || ""}`}
                label={t({
                  id: "inventory-logs.quantity-of-ordered-items",
                  defaultMessage: "Quantity of Ordered items",
                })}
                variant="blue"
              />
            )}
            {unit && (
              <LogParameter
                value={
                  info.stock_on_hand?.toString()
                    ? `${info.stock_on_hand} ${unit || ""}`
                    : null
                }
                label={t({
                  id: "inventory-logs.stock-on-hand",
                  defaultMessage: "Stock on Hand",
                })}
              />
            )}
            {unit && (
              <LogParameter
                value={
                  info.processing_order?.toString()
                    ? `${info.processing_order} ${unit || ""}`
                    : null
                }
                label={t({
                  id: "inventory-logs.total-processing-orders",
                  defaultMessage: "Total processing orders",
                })}
              />
            )}
            {unit && (
              <LogParameter
                value={
                  info.available?.toString()
                    ? `${info.available} ${unit}`
                    : null
                }
                label={t({
                  id: "inventory-logs.available-for-sale",
                  defaultMessage: "Available for sale",
                })}
              />
            )}
            <div className="text-grey-900 font-primary pt-2 text-xs">
              {inserted_at &&
                format(apiUTCToLocalDateTime(inserted_at), "MMM d, h:mm a")}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (action === "stock_info") {
    return (
      <UserGeneralProfile entityId={entity.id} userId={action_by}>
        {({ safeProfile }) => (
          <div className="bg-grey-100 flex w-full gap-3 rounded-[10px] px-4 py-6">
            <div>
              <Avatar
                imageUrl={safeProfile.avatar}
                username={safeProfile.displayName}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-dark font-secondary">
                {isService
                  ? t(
                      {
                        id: "inventory-logs.service-adjusted-by-user",
                        defaultMessage:
                          "Service info was adjusted by <medium>{action_by}</medium>",
                      },
                      {
                        action_by: safeProfile.displayName,
                        medium: (text: (string | JSX.Element)[]) => (
                          <span className="font-medium">{text}</span>
                        ),
                      }
                    )
                  : t(
                      {
                        id: "inventory-logs.stock-info-adjusted-by-user",
                        defaultMessage:
                          "Stock info adjusted by <medium>{action_by}</medium>",
                      },
                      {
                        action_by: safeProfile.displayName,
                        medium: (text: (string | JSX.Element)[]) => (
                          <span className="font-medium">{text}</span>
                        ),
                      }
                    )}
              </div>
              <div className="flex flex-col gap-1">
                <LogParameter
                  value={info.warehouse}
                  label={t({
                    id: "common.warehouse",
                    defaultMessage: "Warehouse",
                  })}
                />
                <LogParameter
                  value={
                    info.prev_price?.toString()
                      ? priceDisplay(info.prev_price * 100, entity.currency)
                      : null
                  }
                  label={t({
                    id: "inventory-logs.previous-price",
                    defaultMessage: "Previous Price",
                  })}
                />
                <LogParameter
                  value={
                    info.updated_price?.toString()
                      ? priceDisplay(info.updated_price * 100, entity.currency)
                      : null
                  }
                  label={t({
                    id: "inventory-logs.updated-price",
                    defaultMessage: "Updated Price",
                  })}
                />
                {!isService && (
                  <LogParameter
                    value={
                      info.adjusted_qty?.toString()
                        ? `${info.adjusted_qty > 0 ? "+" : ""}${
                            info.adjusted_qty
                          } ${unit || ""}`
                        : null
                    }
                    label={t({
                      id: "inventory-logs.adjusted-quantity",
                      defaultMessage: "Adjusted Quantity",
                    })}
                    variant={
                      info.adjusted_qty?.toString() && info.adjusted_qty > 0
                        ? "green"
                        : info.adjusted_qty === 0
                        ? undefined
                        : "red"
                    }
                  />
                )}
                {!isService && (
                  <LogParameter
                    value={
                      info.stock_on_hand?.toString()
                        ? `${info.stock_on_hand} ${unit || ""}`
                        : null
                    }
                    label={t({
                      id: "inventory-logs.stock-on-hand",
                      defaultMessage: "Stock on Hand",
                    })}
                  />
                )}
                <LogParameter
                  value={
                    info.processing_order?.toString()
                      ? `${info.processing_order} ${unit || ""}`
                      : null
                  }
                  label={t({
                    id: "inventory-logs.total-processing-orders",
                    defaultMessage: "Total processing orders",
                  })}
                />
                {!isService && (
                  <LogParameter
                    value={
                      info.available_qty?.toString()
                        ? `${info.available_qty} ${unit || ""}`
                        : null
                    }
                    label={t({
                      id: "inventory-logs.available-for-sale",
                      defaultMessage: "Available for sale",
                    })}
                  />
                )}
                <div className="text-grey-900 font-primary pt-2 text-xs">
                  {inserted_at &&
                    format(apiUTCToLocalDateTime(inserted_at), "MMM d, h:mm a")}
                </div>
              </div>
            </div>
          </div>
        )}
      </UserGeneralProfile>
    );
  }
  return <></>;
};

export const HistoryTab = ({
  itemData,
}: {
  itemData: InventoryItemDataModel;
}) => {
  const { t } = useTranslations();
  const { inventoryApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const { ref: bottomAnchorRef, inView: bottomAnchorInView } = useInView();
  const [logsData, setLogsData] = useState<{
    logs: InventoryLogDataDto[];
    page: number;
    hasMore: boolean;
    isInitialized: boolean;
  }>({
    logs: [],
    page: 0,
    hasMore: true,
    isInitialized: false,
  });
  const [loadLogs] = inventoryApi.useLazyGetInventoryLogsListQuery();

  const handleLoadMoreLogs = useCallback(async () => {
    if (!logsData.hasMore || !itemData) return;
    const response = await loadLogs({
      itemId: itemData.id,
      entityId: entity.id,
      params: {
        page: logsData.page + 1,
        page_size: 10,
      },
    });
    if (response.data && response.data.data) {
      const hasMore = response.data.total_pages > response.data.page_number;

      setLogsData({
        logs: [...logsData.logs, ...response.data.data],
        page: response.data.page_number || 1,
        hasMore,
        isInitialized: true,
      });
    }
  }, [
    logsData.hasMore,
    logsData.page,
    logsData.logs,
    itemData,
    loadLogs,
    entity.id,
  ]);

  useEffect(() => {
    if (bottomAnchorInView) {
      handleLoadMoreLogs();
    }
  }, [bottomAnchorInView, handleLoadMoreLogs]);

  return (
    <div className="align-between flex h-full w-full border-collapse flex-col justify-between gap-4 overflow-hidden overflow-y-auto pt-10">
      {logsData.logs.map((log) => (
        <LogItem
          {...log}
          unit={itemData.unit}
          isService={itemData.category === "service"}
        />
      ))}
      {logsData.isInitialized && logsData.logs.length === 0 && (
        <span className="text-[#828282]">
          {t({
            id: "inventory-logs.no-history-yet",
            defaultMessage: "No History yet",
          })}
        </span>
      )}

      <div ref={bottomAnchorRef}>
        {logsData.hasMore && <LoadingAnimation />}
      </div>
    </div>
  );
};
