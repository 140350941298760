import React from "react";
import { cx, useTranslations } from "@jugl-web/utils";
import { InteractiveContainer } from "@jugl-web/ui-components";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { InventoryItemParams } from "@jugl-web/rest-api";
import { WarehousePicker } from "@web-src/modules/inventory/components/WarehousePicker";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { ReactComponent as MinusIcon } from "./assets/minus.svg";
import { SectionHeader } from "../SectionHeader";
import { MAX_NUMBER_VALUE } from "./consts";
import { ReactComponent as ChevronIcon } from "./assets/chevron.svg";

export const TrackInventorySection: React.FC<{
  register: UseFormRegister<InventoryItemParams>;
  errors: FieldErrors<InventoryItemParams>;
  control: Control<InventoryItemParams, unknown>;
  isTrackInventory: boolean;
  isEdit: boolean;
}> = ({ register, errors, control, isTrackInventory, isEdit }) => {
  const { t } = useTranslations();

  return (
    <div className="flex w-[1000px] flex-col gap-8 px-[60px]">
      <div className="flex items-center gap-8">
        <SectionHeader
          number={3}
          title={t({
            id: "inventory-item-form-page.track-inventory",
            defaultMessage: "Track Inventory",
          })}
          subtitle={t({
            id: "inventory-item-form-page.track-inventory-history-transactions",
            defaultMessage:
              "Track this Item stock based on its history of transactions",
          })}
        />
        <Controller
          control={control}
          name="isTrackInventory"
          render={({ field: { onChange, value } }) => (
            <InteractiveContainer
              className={cx(
                "flex h-9 w-9 items-center justify-center rounded-full",
                isTrackInventory ? "bg-grey-200" : "bg-primary"
              )}
              onClick={() => onChange(!value)}
            >
              {value ? <MinusIcon /> : <PlusIcon />}
            </InteractiveContainer>
          )}
        />
      </div>
      {isTrackInventory && (
        <div className="grid grid-cols-3 gap-10">
          <TextInput
            onKeyDown={(e) => {
              const notAllowedCharacters = ["e", "E", "+", "-"];
              if (notAllowedCharacters.includes(e.key)) {
                e.preventDefault();
              }
            }}
            {...register("stock", {
              required: {
                value: true,
                message: t({
                  id: "inventory-item-form-page.mandatory-field",
                  defaultMessage: "Mandatory field",
                }),
              },

              validate: (value) => {
                if (Number(value) > MAX_NUMBER_VALUE) {
                  return t(
                    {
                      id: "inventory-item-form-page.max-number-value",
                      defaultMessage: "Max value is {maxNumberValue}",
                    },
                    {
                      maxNumberValue: MAX_NUMBER_VALUE,
                    }
                  );
                }
                return true;
              },
            })}
            isRequired
            isInvalid={!!errors.stock}
            label={
              isEdit
                ? t({
                    id: "inventory-item-form-page.stock",
                    defaultMessage: "Stock",
                  })
                : t({
                    id: "inventory-item-form-page.opening-stock",
                    defaultMessage: "Opening Stock",
                  })
            }
            type="number"
            errorMessage={errors.stock?.message}
            min={0}
            placeholder={t({
              id: "common.enter",
              defaultMessage: "Enter",
            })}
          />
          <TextInput
            onKeyDown={(e) => {
              const notAllowedCharacters = ["e", "E", "+", "-"];
              if (notAllowedCharacters.includes(e.key)) {
                e.preventDefault();
              }
            }}
            {...register("reorderPoint", {
              validate: (value) => {
                if (Number(value) > MAX_NUMBER_VALUE) {
                  return t(
                    {
                      id: "inventory-item-form-page.max-number-value",
                      defaultMessage: "Max value is {maxNumberValue}",
                    },
                    {
                      maxNumberValue: MAX_NUMBER_VALUE,
                    }
                  );
                }
                return true;
              },
            })}
            label={t({
              id: "inventory-item-form-page.reorder-point",
              defaultMessage: "Reorder Point",
            })}
            errorMessage={errors.reorderPoint?.message}
            type="number"
            isInvalid={!!errors.reorderPoint}
            min={0}
            placeholder={t({
              id: "common.enter",
              defaultMessage: "Enter",
            })}
          />
          <Controller
            control={control}
            name="warehouse"
            render={({ field: { onChange, value } }) => (
              <WarehousePicker
                value={value}
                onChange={onChange}
                renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                  <Trigger
                    ref={triggerRef}
                    as={InteractiveContainer}
                    className="text-left"
                  >
                    <TextInput
                      label={t({
                        id: "inventory-item-form-page.warehouse",
                        defaultMessage: "Warehouse",
                      })}
                      placeholder={t({
                        id: "inventory-item-form-page.select-warehouse",
                        defaultMessage: "Select Warehouse",
                      })}
                      errorMessage={errors.warehouse?.message}
                      classNames={{
                        inputClassName: "truncate",
                        wrapperClassName: "w-full h-[59px] cursor-pointer",
                        endContentWrapperClassName: "bg-white",
                      }}
                      value={value?.name || ""}
                      endContent={
                        <ChevronIcon
                          className={cx(
                            "absolute right-5 top-[52px] transform transition-transform",
                            isOpen && "rotate-180"
                          )}
                        />
                      }
                    />
                  </Trigger>
                )}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};
